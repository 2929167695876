import routeAPi from "@/api/route.js";
// import { setItem, getItem } from "@/helpers/persistanceStorage";

const state = {
  isLoadingRoute: false, //ожидание запроса
  validationErrors: null, //ошибки
  routes: [],
};

export const mutationTypes = {
  getRouteStart: "[route]  getRouteStart",
  getRouteSuccess: "[route]  getRouteSuccess",
  getRouteFailure: "[route]  getRouteFailure",
};
export const actionTypesRoute = {
  getRoute: "[route]  getRoute",
};

const mutations = {
  [mutationTypes.getRouteStart](state) {
    state.validationErrors = null;
    state.isLoadingRoute = true;
  },
  [mutationTypes.getRouteSuccess](state, payload) {
    console.log(payload,'payload---------')
    state.isLoadingRoute = false;
    state.routes = payload;
  },
  [mutationTypes.getRouteFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoadingRoute = false;
  },
};
const actions = {
  //получение всех юзеров с девайсами
  [actionTypesRoute.getRoute](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRouteStart);
      routeAPi
          .getRoute(data)
          .then((response) => {
            context.commit(mutationTypes.getRouteSuccess, response.data);
            resolve(response.data);
          })
          .catch((err) => {
            context.commit(mutationTypes.getRouteFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};

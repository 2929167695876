import trackersAPi from "@/api/trackers.js";

const state = {
  isLoadingDownload: false, //ожидание запроса
  isLoadingDownloadPdf:false,
  validationErrors: null,
  responseReport: null,
};

export const mutationTypes = {
  downloadReportStart: "[download] downloadReportStart",
  downloadReportSuccess: "[download] downloadReportSuccess",
  downloadReportFailure: "[download] downloadReportFailure",

  downloadReportPdfStart: "[download] downloadReportPdfStart",
  downloadReportPdfSuccess: "[download]downloadReportPdfSuccess",
  downloadReportPdfFailure: "[download] downloadReportPdfFailure",
};
export const actionTypesDownload = {
  downloadReport: "[download] downloadReport",
  downloadReportPdf: "[download] downloadReportPdf"
};

const mutations = {
  //Скачивание отчета
  [mutationTypes.downloadReportStart](state) {
    state.isLoadingDownload = true;
    state.validationErrors = null;
  },
  [mutationTypes.downloadReportSuccess](state, items) {
    state.isLoadingDownload = false;
    state.responseReport = items;
  },
  [mutationTypes.downloadReportFailure](state, payload) {
    state.isLoadingDownload = false;
    state.validationErrors = payload;
  },
  //Скачивание отчета PDF
  [mutationTypes.downloadReportPdfStart](state) {
    state.isLoadingDownloadPdf = true;
    state.validationErrors = null;
  },
  [mutationTypes.downloadReportPdfSuccess](state, items) {
    state.isLoadingDownloadPdf = false;
    state.responseReport = items;
  },
  [mutationTypes.downloadReportPdfFailure](state, payload) {
    state.isLoadingDownloadPdf = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesDownload.downloadReport]: (context, info) => {
    const { commit } = context;
    return new Promise((resolve, reject) => {
      commit(mutationTypes.downloadReportStart);
      trackersAPi
        .downloadReport(info)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const fileName = `${info.serial}.xlsx`;
          const reportData = {
            url,
            fileName,
          };
          commit(mutationTypes.downloadReportSuccess, reportData);
          resolve(reportData);
        })
        .catch((error) => {
          commit(mutationTypes.downloadReportFailure, error);
          reject(error);
        });
    });
  },
  [actionTypesDownload.downloadReportPdf]: (context, info) => {
    const { commit } = context;
    return new Promise((resolve, reject) => {
      commit(mutationTypes.downloadReportPdfStart);
      trackersAPi
          .downloadReportPdf(info)
          .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], { type: "application/vnd.ms-excel" })
            );
            const fileName = `${info.serial}.pdf`;
            const reportData = {
              url,
              fileName,
            };
            commit(mutationTypes.downloadReportPdfSuccess, reportData);
            resolve(reportData);
          })
          .catch((error) => {
            commit(mutationTypes.downloadReportPdfFailure, error);
            reject(error);
          });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="index">
<!--          @itemTrackerData="itemTrackerData"-->
    <SideNav
      @itemIndex="itemIndex"
      @zoomToMarker="zoomToMarker"
      @clickSettings="clickSettings"
      @getOpenReport="getOpenReport"
      @toggle-route-visibility="handleToggleRouteVisibility"
      ref="sideBar"
    />
    <MapVue2Leaflet @itemIndex="itemIndex" :isRouteVisible="isRouteVisible"  ref="mapView" />
    <report v-if="isOpenReport" @close="closeReport" />
    <ModalBlock @itemIndex="itemIndex" v-bind:item_light="itemLight" />
    <modal-block-power
      v-bind:item_light="itemLight"
      ref="modalPower"
    ></modal-block-power>
    <modal-block-history v-bind:item_light="itemLight"></modal-block-history>

    <modal-timer v-bind:item_light.sync="itemLight"></modal-timer>

    <modal-timer-history v-bind:item_light="itemLight"></modal-timer-history>
    <modal-report />
    <modal-change-coordinates
      v-bind:item_light="itemLight"
    ></modal-change-coordinates>
    <modal-all-timers></modal-all-timers>
    <modal-all-block-power></modal-all-block-power>
    <modal-info-dimer></modal-info-dimer>
    <modal-about-program></modal-about-program>
    <modal-add-block></modal-add-block>
    <modal-delete v-bind:item_light="itemLight"></modal-delete>
    <modal-tracker-property @closeModalTrackerProperty="closeModalTrackerProperty"/>
    <modal-control-tracker @closeModalControlTracker="closeModalControlTracker"/>
    <modal-delete-tracker></modal-delete-tracker>
    <app-card-routing v-if="openCardRouting"/>
    <!-- <modal-block></modal-block> -->
  </div>
</template>

<script>
import ModalBlock from "../components/ModalBlock.vue";
import SideNav from "../components/SideNav.vue";
import M from "materialize-css";
import MapVue2Leaflet from "../components/MapVue2Leaflet.vue";
import { actionTypes } from "@/store/modules/lights";
import { actionTypesTracker } from "@/store/modules/trackers";
// import { actionTypesSocket } from "@/store/modules/socket";
// import { actionTypesDemir } from "@/store/modules/demir";
import ModalBlockPower from "../components/ModalBlockPower.vue";
import ModalBlockHistory from "../components/ModalBlockHistory.vue";
import { mapState } from "vuex";
import ModalTimer from "../components/ModalTimer.vue";
import ModalTimerHistory from "../components/ModalTimerHistory.vue";
import ModalChangeCoordinates from "../components/ModalChangeCoordinates.vue";
import ModalAllTimers from "../components/ModalAllTimers.vue";
import ModalInfoDimer from "../components/ModalInfoDimer.vue";
import ModalAllBlockPower from "../components/ModalAllBlockPower.vue";
import ModalAboutProgram from "../components/ModalAboutProgram.vue";
import ModalAddBlock from "../components/ModalAddBlock";
import ModalDelete from "../components/ModalDelete.vue";
import { actionTypesSocket } from "@/store/modules/socket";
import ModalTrackerProperty from "../components/Tracker/ModalTrackerProperty.vue";
import ModalControlTracker from "@/components/Tracker/ModalControlTracker.vue";
import ModalReport from "../components/ModalView/ModalReport.vue";
import Report from "../components/Report/Report.vue";
import ModalDeleteTracker from '@/components/ModalDeleteTracker.vue'
import AppCardRouting from '@/components/Routing/CardRouting.vue'
export default {
  name: "AppIndex",
  data() {
    return {
      title: "Глонасс мониторинг",
      itemLight: null,
      // itemTracker: null,
      modalInstance: null,
      checkItem: [],
      isOpenReport: false,
      isRouteVisible: false,
      // modalTrackerProperty: null,
    };
  },
  components: {
    AppCardRouting,
    ModalDeleteTracker,
    SideNav,
    MapVue2Leaflet,
    ModalBlock,
    ModalBlockPower,
    ModalBlockHistory,
    ModalTimer,
    ModalTimerHistory,
    ModalChangeCoordinates,
    ModalAllTimers,
    ModalInfoDimer,
    ModalAllBlockPower,
    ModalAboutProgram,
    ModalAddBlock,
    ModalDelete,
    ModalTrackerProperty,
    ModalControlTracker,
    ModalReport,
    Report,
  },
  methods: {
    itemIndex(items) {
      // console.log(items, "Index SCREEN");
      this.itemLight = items;
    },
    closeModalTrackerProperty() {
      console.log('hhh')
      this.modalTrackerProperty.close()
    },
    closeModalControlTracker() {
      console.log('ASFFFFFFsgddfgd')
      this.modalControlTracker.close()
    },
    clickSettings() {
      this.$refs.modalPower.addRowMount();
    },
    zoomToMarker(item) {
      // this.coordinates = item.coordinate;
      this.$refs.mapView.openPopUp(item.coordinate);
    },
    getOpenReport() {
      this.isOpenReport = !this.isOpenReport;
    },
    closeReport() {
      this.isOpenReport = !this.isOpenReport;
      this.$refs.sideBar.openSideBar();
    },
    handleToggleRouteVisibility(check) {
        this.isRouteVisible = check;
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      userInfo: (state) => state.auth.userInfo,
      isConnected: (state) => state.socket.isConnected,
      openCardRouting: (state) => state.allModal.openCardRouting
    }),
  },
  beforeMount() {
    document.title = this.title;
  },
  beforeUpdate() {
    document.title = this.title;
  },

  mounted() {
    if (this.token === "undefined" || this.token === "") {
      this.$router.push({ name: "Login" });
    }
    M.AutoInit();
    //Устанавливаем инидиализацию модального окна Tracker
    // this.$store.commit("modal/setModalTrackerProperty", M.Modal.init(this.$el.querySelector("#modalTrackerProperty")));

    // this.modalTrackerProperty = M.Modal.init(this.$el.querySelector("#modalTrackerProperty"));
    // this.$nextTick(function () {
    //   const elem = document.querySelector(".sidenav");
    //   this.modalInstance = M.Sidenav.init(elem);
    // });
    if (this.userInfo.id && !this.isConnected) {
      this.$store.dispatch(actionTypesSocket.CONNECT, this.userInfo.id);
      // this.$store.dispatch(actionTypesSocket.socketConnect, this.userInfo.id);
    }

    this.$store
      .dispatch(actionTypes.getList)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });

    this.$store
      .dispatch(actionTypesTracker.getList)
      .then(() => {

      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

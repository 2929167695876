<template>
  <div class="">
    <div class="" style="height: calc(100% - 100px)">
      <div
          class=""
          style="overflow-y: auto; height: calc(100% - 77px)"
          v-if="trackers"
      >
        <item-tracker
            v-for="(itemTrack, index) in filteredListTrackers"
            :key="itemTrack.id"
            :index="index"
            :item_tracker="itemTrack"
            @zoomToMarker="zoomToMarker"
            @updateChechTracker="updateChechTracker"
            v-model="checkedTracker"
            :isActive="isActive"
        />
        <!-- @handleClick="handleClick" -->
      </div>
    </div>
    <div class="sidenav__checkbox-all index-color">
      <p>
        <label>
          <input :disabled="isActive" type="checkbox" class="filled-in" checked="checked" v-model="selectAllTracker"/>

          <span class="title-text">Выбрать все</span>
        </label>
      </p>
      <div
          class="sidenav__send-button tooltipped modal-trigger"
          data-target="modalAllTimers"
          name="action"
          data-position="top"
          :data-tooltip="'Отправить всем'"
          v-if="this.checkedTracker.length > 1"
      >
        <i class="material-icons white-text right">send</i>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import {actionTypesTracker} from "@/store/modules/trackers";
import ItemTracker from "./ItemTracker.vue";

export default {
  components: {ItemTracker},
  name: "AppListTracker",
  props: {
    filteredListTrackers: {
      type: Array,
      default() {
        return {};
      },
    },
    isActive: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      checkedTracker: [],
    };
  },

  methods: {
    updateChechTracker() {
      this.$store.dispatch(
          actionTypesTracker.checkedTracker,
          this.checkedTracker
      );
    },
    zoomToMarker(item) {
      console.log("====================================");
      console.log(item);
      console.log("====================================");
      this.$emit("zoomToMarker", item);
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.lights.isSubmitting,
      trackers: (state) => state.trackers.allTrackers,
    }),
    selectAllTracker: {
      get: function () {
        return this.trackers
            ? this.checkedTracker.length == this.trackers.length
            : false;
      },
      set: function (value) {
        var checked = [];

        if (value) {
          this.trackers.forEach(function (trackers) {
            checked.push(trackers);
          });
        }

        this.checkedTracker = checked;
        this.$store.dispatch(
            actionTypesTracker.checkedTracker,
            this.checkedTracker
        );
      },
    },
  },
};
</script>

<style>

[type="checkbox"].filled-in:checked + span:not(.lever):before {

  border-right: 2px solid #ffffff !important;
  border-bottom: 2px solid #ffffff !important;
  /* if just wanted to change the color of tick mark you can change the above borders 	 */

}

[type="checkbox"].filled-in:checked + span:not(.lever):after {

  border: 2px solid #6378A6 !important;
  background-color: #6378A6 !important;

  /* if you want to change the filling color when the user checks the box by mouse click you can change the above values */
}

[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {

  background-color: #414141 !important;
  border-color: #414141 !important;
}

[type="checkbox"].filled-in.tabbed:checked:disabled + span:not(.lever):after {

  background-color: #414141 !important;
  border-color: #414141 !important;
}
[type="checkbox"].filled-in:checked:disabled + span:not(.lever):after {

  background-color: #b4b4b4 !important;
  border-color: #b4b4b4 !important;
  border-right: 2px solid #b4b4b4 !important;
  border-bottom: 2px solid #b4b4b4 !important;
}

</style>

<template>
  <form id="form-general-property" class="form-general-property">
<!--    <div class="modal-info__wrap-margin">-->
<!--      <p class="title-mini index-color-text">Общие свойства</p>-->
<!--    </div>-->
    <div class="row">
      <div class="input-field col s6">
        <input
          id="model"
          name="name"
          type="text"
          v-model="normalizedTracker.name"
          @change="isChangeName = true"
        />
        <label class="active" for="text">Заданое имя</label>
      </div>
      <div class="input-field col s6">
        <input
          disabled
          id="serial"
          type="text"
          v-model="normalizedTracker.serial"
        />
        <label class="active" for="text">Код устройства</label>
      </div>
    </div>
    <div class="row">
      <div class="input-field col s4">
          <input
          disabled
          id="brand"
          placeholder="Марка"
          name="carBrand"
          class="validate input-margin"
          type="text"
          v-model="normalizedTracker.carBrand"
          />
        <label class="active" for="text">Марка машины</label>
      </div>
      <div class="input-field col s4">
        <input
            disabled
            id="regNumber"
            placeholder="Номер машины"
            name="registrationNumber"
            class="validate input-margin"
            type="text"
            v-model="normalizedTracker.registrationNumber"
        />
        <label class="active" for="text">Номер машины</label>
      </div>
      <div class="input-field col s4">
        <input
            disabled
            id="region"
            placeholder="Регион"
            name="registrationRegion"
            class="validate input-margin"
            type="text"
            v-model="normalizedTracker.registrationRegion"
        />
        <label class="active" for="text">Регион</label>
      </div>
    </div>
    <div class="row no-margin">
      <div class="input-field col s6">
          <input
            disabled
            placeholder="Модель"
            id="disabled"
            type="text"
            class="validate"
            v-model="normalizedTracker.gprsResponse.deviceModel"
          />
        <label class="active" for="text">Модель устройства</label>
      </div>

      <div class="input-field col s6">
        <input
            disabled
            placeholder="+7(***)**-**"
            id="disabled"
            type="tel"
            v-mask="'+###########'"
            name="phoneNumber"
            class="validate"
            v-model="normalizedTracker.gprsResponse.phoneNumber"
        />
        <label class="active" for="disabled">SIM-карта</label>
      </div>
    </div>

<!--    <div class="col s12">-->

<!--    </div>-->
    <div class="col s12 no-margin">
<!--      <div class="modal-info__title-params">-->
<!--        <p class="modal-info__title-mini black-text">Настройка SMS параметров</p>-->
<!--      </div>-->
      <div class="row display-end no-margin">
        <div class="input-field col s4">
          <div class="waiting-submit"  v-if="normalizedTracker.commandResponse.changeUssdBalance" >
            <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right">Обновление отправлено</span>
            <img src="../../assets/svg/network-activity.svg" alt="wait">
          </div>
          <input
              disabled
              placeholder="(по умолчанию *100#)"
              id="balanceRequest"
              type="text"
              name="balanceRequest"
              class="validate input-margin"
              v-model="normalizedTracker.gprsResponse.balanceRequest"
              @input="handleInputChange(8)"
          />
          <label class="active" for="balanceRequest">Команда баланса</label>
        </div>
        <div class="input-field col s4">
          <div class="waiting-submit" v-if="normalizedTracker.commandResponse.changeLowBalanceAlert">
            <span class="helper-text orange-text text-accent-4" data-error="wrong" data-success="right" >Обновление отправлено</span>
            <img src="../../assets/svg/network-activity.svg" alt="wait">
          </div>
          <input
              disabled
              id="lowBalanceAlert"
              type="number"
              min="1"
              name="lowBalanceAlert"
              class="validate input-margin"
              v-model="normalizedTracker.gprsResponse.lowBalanceAlert"
          />
          <label class="active" for="lowBalanceAlert">Порог баланса в рублях</label>
        </div>
        <div>
          <div class="input-field col s12">
            <select-time-zone
                :nowTimeZone="normalizedTracker.gprsResponse.timezone"
                @timezoneSelected="timezoneSelected"
                ref="timeZone"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { actionTypesTracker } from '@/store/modules/trackers'
import SelectTimeZone from "@/components/SelectTimeZone.vue";

export default {
  name: "AppGeneralProperties",
  components: {SelectTimeZone},
  data() {
    return {
      newName: null,
      isChangeName: false,
    };
  },
  props: {
    normalizedTracker: {
      type: Object,
      default() {
        return;
      },
    },
  },
  methods: {
    updateNewSettings() {
      if (this.isChangeName) {
        this.$store
            .dispatch(actionTypesTracker.changeNameTracker,
                { "beaconId": this.normalizedTracker.beaconId,
                  "name":this.normalizedTracker.name })
            .then((response) => {
              this.normalizedTracker.name = response
              this.isChangeName = false
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }

    },
  },
};
</script>

<style scoped>

input:disabled {
  background-color: #f0f0f0; /* Изменяет цвет фона */
  color: #151515 !important;/* Изменяет цвет текста */
  border: 1px solid #cccccc; /* Изменяет цвет рамки */
}

.no-margin {
  margin: 0 auto !important;
}

</style>

<template>
  <div class="routing-container right s9 m9">
    <div class="routing-container__100 row">
      <div class="col right s9 m9">
        <!-- Отчет -->
        <div class="card white routing">
          <div class="routing__header index-color">
            <div class="routing__header-title">
              <p class="white-text">Постройте маршрут следования по заданному времени</p>
            </div>

            <div class="routing__header-cross" @click="closeCard">
              <i class="material-icons white-text"> close</i>
            </div>
          </div>
          <div class="routing__point">
            <div class="routing__point-block">
            <p class="routing__point-text">1) Выберите трекер(-ы)</p>
              <img src="../../assets/svg/success.svg" alt="success" class="routing__success" v-if="checkedTrackers.length !== 0">
            </div>
            <div class="routing__point-block">
            <p class="routing__point-text">2) Задайте временной отрезок</p>
              <img src="../../assets/svg/success.svg" alt="success" class="routing__success" v-if="startDate && endDate !== null">
            </div>
          </div>
          <div class="routing__body">
            <div class="time-zone-route">
              <div class="time-zone-route__wrap">

                <date-picker
                    v-model="startDate"
                    type="datetime"
                    placeholder="Дата начала периода"
                    class="time-zone-route__time"
                ></date-picker>
              </div>
              <div class="time-zone-route__wrap">

                <date-picker
                    v-model="endDate"
                    type="datetime"
                    placeholder="Дата конца периода"
                    class="time-zone-route__time"
                ></date-picker>
              </div>
            </div>
            <div class="routing__btn-create right">
              <button
                  class="waves-effect waves-light btn btn-medium dark-green-color"
                  @click="getRoutingCar"
                  :disabled="checkedTrackers.length === 0 || !startDate || !endDate"
              >
               Построить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { actionTypesModal } from '@/store/modules/all-modal'
  import { actionTypesRoute } from '@/store/modules/route'
  import moment from 'moment-timezone';

  export default {
    name: "AppCardRouting",

    data() {
      return {
        modalInstance: null,
        startDate: null,
        endDate: null,
        // momentFormat: {
        //   // Date to String
        //   stringify: (date) => {
        //     return date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';
        //   },
        //   // String to Date
        //   parse: (value) => {
        //     return value ? moment(value, 'YYYY-MM-DDTHH:mm').toDate() : null;
        //   },
        //   // Get week number (optional)
        //   getWeek: (date) => {
        //     return moment(date).week();
        //   }
        // }
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.trackers.isLoading,
        allReportsTr: (state) => state.trackers.allReportsTr,
        checkedTrackers: (state) => state.trackers.checkedTrackers,
        routes: (state) => state.route.routes

      }),
    },
    methods: {
      closeCard() {
        this.$store.dispatch(actionTypesModal.closeModalRouting)
      },
      getRoutingCar() {
        const formattedStartDate = this.startDate ? moment(this.startDate).format('YYYY-MM-DD HH:mm') : null;
        const formattedEndDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DD HH:mm') : null;
        // Извлечение serial из checkedTrackers
        const serialsList = this.checkedTrackers.map(tracker => tracker.serial);

        this.$store.dispatch(actionTypesRoute.getRoute, {
          serials: serialsList ,
          startDate: formattedStartDate,
          endDate: formattedEndDate
        }).then((response) => {
          if(response.length === 0) {
          alert('Маршрут на данном временном отрезке не найден!');
          } else {
            console.log(response)
          }

        })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },
    mounted() {

    },
  };
</script>

<style></style>

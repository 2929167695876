<template>
  <div class="row arbitrary-command">
    <div class="">
      <label class="active" for="disabled">Введите произвольную команду</label>
    </div>
    <div class="">
    <div class="input-field col s4">
      <input
          placeholder="GS00,"
          id="disabled"
          type="text"
          name="prefix"
          class="validate input-margin"
          value="GS00,"
          readonly
          disabled
      />

    </div>
    <div class="input-field col s6">
      <input
          placeholder="Введите произвольную команду"
          id="userInput"
          type="text"
          name="userInput"
          class="validate input-margin"
          v-model="command"

      />
    </div>
    </div>
      <div class="modal-info__footer-wrap">
        <button
            type="button"
            @click="submitCommand()"
            name="btn_login"
            class="col s4 btn waves-effect waves-light index-color"
        >
          Отправить
        </button>
    </div>
  </div>
</template>

<script>
  import { actionTypesTracker } from '@/store/modules/trackers'

  export default {
    name: "AppArbitraryCommand",
    data() {
      return {
        command: '',
      };
    },
    props: {
      normalizedTracker: {
        type: Object,

      },
    },
    methods: {
      submitCommand() {
        this.$store
            .dispatch(actionTypesTracker.submitNewCommand,
                { "serial": this.normalizedTracker.serial,
                  "command":this.command } )
            .then(() => {
              this.command= ''
              this.$store
                  .dispatch(actionTypesTracker.getAllCommands, this.normalizedTracker.serial)
                  .then(() => {})
                  .catch((er) => {
                    console.log("Ошибка", er);
                  });
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });

      }
    }



  };
</script>

<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalControlTracker" class="modal">
      <div id="modalTracker" action="">
        <div class="modal-content" v-if="itemTracker !== null">
          <div class="modal-info__title-block index-color">
            <div class="modal-info__wrap-title">
              <img
                  src="../../assets/svg/car.svg"
                  alt="car"
                  class="shema-block__img-svg"
              />
              <div class="modal-info__title">
                <p class="title-mini white-text">
                  Управление: {{ itemTracker.name }}
                </p>
              </div>
            </div>
            <!--            modal-close-->
            <div class="modal-info__close" id="closeBtn" @click="closeModal()">
              <i class="material-icons white-text"> close</i>
            </div>
          </div>

          <div class="spinner-container" v-if="isLoadingSend || isLoadingChangeName">
            <div class="spinner-overlay"></div>
            <div class="spinner">
              <div class="spinner-icon"></div>
            </div>
          </div>

          <div class="modal-info__info" >
            <div class="row modal-info__wrap-info no-margin">
<!--              <div class="row modal-form col s6 modal-scroll-max no-margin" v-if="isOpenCommand">-->

<!--                <AppArbitraryCommand  :normalizedTracker="itemTracker"/>-->
<!--              </div>-->

<!--              <div class="row modal-form col s6 modal-scroll-max" v-if="!isOpenCommand && itemTracker.gprsResponse">-->
<!--                <general-properties-->
<!--                    ref="generalProperties"-->
<!--                    :normalizedTracker="itemTracker"-->
<!--                />-->

<!--                <settings-tracker-->
<!--                    ref="settingsTracker"-->
<!--                    @openModalWar="openModalWar"-->
<!--                    :normalizedTracker="itemTracker"-->
<!--                />-->
<!--              </div>-->
              <div class="row modal-form col s6 modal-scroll-max waiting" v-if="!itemTracker.gprsResponse && !isOpenCommand ">

                <div class="waiting__img">
                  <img src="../../assets/svg/network-activity.svg" alt="waiting">
                </div>
                <div class="waiting__text">
                  <p>Ожидаем сообщение с устройства</p>
                </div>

              </div>
              <div class="row modal-info col s12 modal-scroll-max">
                <!-- журнал активных записей -->
                <active-change-log />
              </div>
            </div>

          </div>

          <div class="modal-info__footer">
<!--            <div class="modal-info__footer-tab-settings ">-->
              <div class="modal-info__footer-wrap flex-container">
                <button
                    v-if="!isOpenCommand"
                    type="button"
                    @click="sendCommand('RELOAD_DEVICE')"
                    name="reload-device"
                    class="custom-button"
                >
                  <img src="@/assets/glonas/reload-svgrepo-com.svg" alt="Перезагрузка устройства" class="icon" />
                  Перезагрузка устройства
                </button>
                <button
                    v-if="!isOpenCommand"
                    type="button"
                    @click="sendCommand('START_ENGINE')"
                    name="start-engine"
                    class="custom-button"
                >
                  <img src="@/assets/glonas/key-svgrepo-com.svg" alt="Запуск двигателя" class="icon" />
                  Запуск двигателя
                </button>

                <button
                    v-if="!isOpenCommand"
                    type="button"
                    @click="sendCommand('STOP_ENGINE')"
                    name="stop-engine"
                    class="custom-button"
                >
                  <img src="@/assets/glonas/lock-slash-svgrepo-com.svg" alt="Заблокировать двигатель" class="icon" />
                  Заблокировать двигатель
                </button>

                <button
                    v-if="!isOpenCommand"
                    type="button"
                    @click="sendCommand('GET_LOCATION')"
                    name="get-location"
                    class="custom-button"
                >
                  <img src="@/assets/glonas/location-pin-svgrepo-com.svg" alt="Запрос локации" class="icon" />
                  Запрос локации
                </button>


<!--                <button-->
<!--                    v-else-->
<!--                    type="button"-->
<!--                    @click="openViewCommand"-->
<!--                    name="btn_login"-->
<!--                    class="col s12 btn waves-effect dark-green-color custom-button"-->
<!--                >-->
<!--                  Вернуться к основным настройкам-->
<!--                </button>-->
              </div>
<!--            </div>-->

<!--            <div class="modal-info__footer-tab-submit">-->
<!--              <div class="modal-info__footer-wrap">-->
<!--                <button-->
<!--                    type="button"-->
<!--                    name="btn_login"-->
<!--                    class="modal-close col s12 btn black-text waves-effect gray-color"-->
<!--                >-->
<!--                  Отменить-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="modal-info__footer-wrap">-->
<!--                <button-->
<!--                    type="button"-->
<!--                    @click="updateProperty()"-->
<!--                    name="btn_login"-->
<!--                    class="col s12 btn waves-effect waves-light index-color"-->
<!--                >-->
<!--                  Сохранить-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
        <AppModalClose @closeModalClose="closeModalClose" @allModalClose="allModalClose"/>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

import ActiveChangeLog from "./ActiveChangeLog.vue";
// import GeneralProperties from "./GeneralProperties.vue";
// import SettingsTracker from "./SettingsTracker.vue";
import { mapState } from 'vuex'
import { actionTypesModal } from '@/store/modules/all-modal'
// import AppArbitraryCommand from '@/components/Tracker/ArbitraryCommand.vue'
import AppModalClose from '@/components/ModalClose.vue'
import axios from "axios";
// import trackers from '@/store/modules/trackers'
export default {
  name: "AppModalControlTracker",
  data() {
    return {
      modalInstance: null,
      modalClose: null,
      selectedTimezone: null,
      newName: null,
      isOpenCommand: false,
    };
  },
  components: { AppModalClose, ActiveChangeLog },//AppArbitraryCommand
  computed: {
    ...mapState({
      itemTracker: (state) => state.trackers.itemTracker,
      isLoadingSend: (state) =>  state.trackers.isLoadingSend,
      isLoadingChangeName: (state) => state.trackers.isLoadingChangeName
    }),
  },
  methods: {
    updateProperty() {
      this.$refs.generalProperties.updateNewSettings();
      this.$refs.settingsTracker.updateNewSettings();
    },
    openModalWar(bool) {
      console.log(bool, 'передал')
      if (bool) {
        console.log('Есть изменения')
        this.modalClose.open();
      } else {
        this.$store.dispatch(actionTypesModal.closeModalTracker)
      }
    },
    closeModalClose() {
      this.modalClose.close();
    },
    allModalClose() {
      this.modalClose.close();
      this.$store.dispatch(actionTypesModal.closeModalTracker)
    },
    closeModal() {
      this.$store.dispatch(actionTypesModal.closeModalTrackerControl)
    },
    openViewCommand() {
      this.isOpenCommand = !this.isOpenCommand
    },

    // Новый метод для отправки произвольной команды
    sendCommand(commandType) {
      const commandRequest = {
        serial: this.itemTracker.serial, // предполагается, что у itemTracker есть поле serial
        commandType: commandType // Используем переданный тип команды
      };

      axios.post('/add/command', commandRequest)
          .then(response => {
            alert('Команда успешно выполнена', response.data);
            // Здесь вы можете обработать успешный ответ, например, показать уведомление
          })
          .catch(error => {
            alert('Произошла ошибка', error);
            // Здесь вы можете обработать ошибку, например, показать сообщение об ошибке
          });
      this.closeModal();
    }
  },
  mounted() {
    this.modalClose = M.Modal.init(this.$el.querySelector("#modalClose"));
    M.updateTextFields();
  },
};
</script>

<style scoped >
.modal {
  width: 43% !important;
}
.modal {
  top: 2% !important;
  max-height: 100vh !important;
}

/*.modal-content {
  overflow-y: auto !important;
}
.modal-form {
  height: 100% !important;
} */
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}

.custom-button {
  background-color: #6378A6; /* Светлый цвет фона */
  color: #ffffff; /* Темно-синий цвет текста */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22%;
  align-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Небольшая тень для объема */
  transform: translateY(0); /* Начальное положение для эффекта объема */

  &:hover {
    background-color: #5069A1; /* Более темный светлый цвет при наведении */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Увеличение тени при наведении */
    transform: translateY(-2px); /* Подъем кнопки при наведении */
  }

  &:active {
    background-color: #5069A1; /* Ещё более темный светлый цвет при активации */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Уменьшение тени при нажатии */
    transform: translateY(1px); /* Нажатие кнопки вниз */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(44, 53, 93, 0.1); /* Темно-синий тень при фокусе */
  }
}

.icon {
  margin-right: 10px; /* Отступ между иконкой и текстом */
  width: 24px;
  height: 24px;
}

.no-margin {
  margin: 0 !important;;
}

.modal-scroll-max {
  margin: auto;
}

.flex-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
  max-width: 100%;
}

</style>

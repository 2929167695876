<template>
  <header>
    <nav class="white">
      <div class="nav-wrapper">
        <div class="brand-logo left header-logo" style="display: flex; align-items: center;">
          <img src="../assets/glonas/icon.png" alt="logo" class="img-logo" style="width: 9vh; height: auto; margin-right: 10px; "/>
          <p style="margin: 0; color: #414141; font-size: 20px; font-weight: 600;">ГлонассДВ</p>
        </div>

        <ul
            id="nav-mobile"
            class="right hide-on-med-and-down"
            style="height: 100%"
        >
          <!--Вариант использования модального окна modal-trigger data-target="modalReport" -->
<!--          <li class="" @click="openModalRouting()">-->
<!--            <router-link class="black-text" :to="'/'"-->
<!--            ><p>Построение маршрутов следования</p>-->
<!--            </router-link>-->
<!--          </li>-->
<!--          <li class="" @click="getOpenReport()">-->
<!--            <router-link class="black-text" :to="'/'"-->
<!--            ><p>Отчеты</p>-->
<!--            </router-link>-->


<!--          </li>-->
<!--          <li-->
<!--              class="modal-trigger"-->
<!--              data-target="modalHistory"-->
<!--              @click="getHistoryAll()"-->
<!--          >-->
            <!--            <router-link class="black-text" :to="'/'"-->
            <!--              ><p>История</p>-->
            <!--            </router-link>-->
<!--          </li>-->


          <li class="modal-trigger" data-target="modalAboutProgram">
            <router-link class="black-text" :to="'/'"
            ><p>О программе</p>
            </router-link>
          </li>

          <li class="exit-wrap">
            <img
                class="exit-wrap__img black-text"
                src="../assets/svg/logout.svg"
                alt="logout"
                @click="exit()"
            />
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>


<script>
import { actionTypes } from "@/store/modules/auth";
import { actionTypesHistory } from "@/store/modules/history";
import { actionTypesSocket } from "@/store/modules/socket";
import { actionTypesModal } from '@/store/modules/all-modal'

export default {
  name: "AppHeader",
  data() {
    return {
      modalInstance: null,
    };
  },
  props: {
    isOpenSideNave: {
      type: Boolean,
      default() {
        return {};
      },
    },
  },
  methods: {
    exit() {
      this.$store
        .dispatch(actionTypes.exitLogin)
        .then(() => {
          this.$store.dispatch(actionTypesSocket.socketClose);
          // socketClose
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    getHistoryAll() {
      this.$store
        .dispatch(actionTypesHistory.getHistoryLogs)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    getOpenReport() {
      if(this.isOpenSideNave) {
        this.$emit("getOpenReport");
      } else this.$emit("getCloseReport");

    },
    openModalRouting() {
        this.$store.dispatch(actionTypesModal.openModalRouting)
    }
  },
  mounted() {},
};
</script>

<style>
/* header {
  padding-left: 300px;
} */
</style>

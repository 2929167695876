<template>
  <div class="">
    <div class="shema-block__title-wrap">
      <label class="shema-block__checkbox-wrap">
        <input
          type="radio"
          class="filled-in black-text"
          :value="item_tracker"
          @change="onChange()"
          v-model="checkedTracker"
        />

        <span class=""></span>
      </label>
      <div class="item-block__info">
        <div class="item-block__info-name">
          <blockquote>
            <div class="item-block__title">
              <div class="shema-block__img">
                <img
                  src="../../../assets/svg/car.svg"
                  alt="car"
                  class="shema-block__img-svg"
                />
              </div>
              <h6 class="index-color-text">{{ this.item_tracker.name }}</h6>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppItemTracker",
  props: {
    item_tracker: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return {};
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateChechTracker");
    },
  },
  computed: {
    checkedTracker: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedP = val;
      },
    },
  },
};
</script>

<style></style>

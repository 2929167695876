<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="25px"
    height="25px"
    version="1.1"
    class="block-track__battery-img"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 0.16 0.3"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Слой_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        v-bind:class="classFour"
        d="M0.02 0.03l0.03 0 0 -0.02c0,-0.01 0,-0.01 0,-0.01l0.06 0c0,0 0,0 0,0.01l0 0.02 0.03 0c0.01,0 0.02,0.01 0.02,0.02l0 0.23c0,0.01 -0.01,0.02 -0.02,0.02l-0.12 0c-0.01,0 -0.02,-0.01 -0.02,-0.02l0 -0.23c0,-0.01 0.01,-0.02 0.02,-0.02z"
      />
      <path
        v-bind:class="classTrird"
        d="M0.02 0.03l0.03 0 0 -0.02c0,-0.01 0,-0.01 0,-0.01l0.06 0c0,0 0,0 0,0.01l0 0.02 0.03 0c0.01,0 0.02,0.01 0.02,0.02l0 0.23c0,0.01 -0.01,0.02 -0.02,0.02l-0.12 0c-0.01,0 -0.02,-0.01 -0.02,-0.02l0 -0.23c0,-0.01 0.01,-0.02 0.02,-0.02zm0.03 0.02l0.07 0c0.01,0 0.02,0.01 0.02,0.02l0 0.04c0,0.02 -0.01,0.02 -0.02,0.02l-0.07 0c-0.02,0 -0.03,0 -0.03,-0.02l0 -0.04c0,-0.01 0.01,-0.02 0.03,-0.02z"
      />
      <path
        v-bind:class="classSecond"
        d="M0.02 0.03l0.03 0 0 -0.02c0,-0.01 0,-0.01 0,-0.01l0.06 0c0,0 0,0 0,0.01l0 0.02 0.03 0c0.01,0 0.02,0.01 0.02,0.02l0 0.23c0,0.01 -0.01,0.02 -0.02,0.02l-0.12 0c-0.01,0 -0.02,-0.01 -0.02,-0.02l0 -0.23c0,-0.01 0.01,-0.02 0.02,-0.02zm0.03 0.02l0.07 0c0.01,0 0.02,0.01 0.02,0.02l0 0.1c0,0.01 -0.01,0.02 -0.02,0.02l-0.07 0c-0.02,0 -0.03,0 -0.03,-0.02l0 -0.1c0,-0.01 0.01,-0.02 0.03,-0.02z"
      />
      <path
        v-bind:class="classFirst"
        d="M0.02 0.03l0.03 0 0 -0.02c0,-0.01 0,-0.01 0,-0.01l0.06 0c0,0 0,0 0,0.01l0 0.02 0.03 0c0.01,0 0.02,0.01 0.02,0.02l0 0.23c0,0.01 -0.01,0.02 -0.02,0.02l-0.12 0c-0.01,0 -0.02,-0.01 -0.02,-0.02l0 -0.23c0,-0.01 0.01,-0.02 0.02,-0.02zm0.03 0.02l0.07 0c0.01,0 0.02,0.01 0.02,0.02l0 0.16c0,0.01 -0.01,0.02 -0.02,0.02l-0.07 0c-0.02,0 -0.03,-0.01 -0.03,-0.02l0 -0.16c0,-0.01 0.01,-0.02 0.03,-0.02z"
      />
      <path
        v-bind:class="classBasic"
        d="M0.02 0.03l0.03 0 0 -0.02c0,-0.01
      0,-0.01 0,-0.01l0.06 0c0,0 0,0 0,0.01l0 0.02 0.03 0c0.01,0 0.02,0.01
      0.02,0.02l0 0.23c0,0.01 -0.01,0.02 -0.02,0.02l-0.12 0c-0.01,0 -0.02,-0.01
      -0.02,-0.02l0 -0.23c0,-0.01 0.01,-0.02 0.02,-0.02zm0.03 0.02l0.07 0c0.01,0
      0.02,0.01 0.02,0.02l0 0.19c0,0.01 -0.01,0.02 -0.02,0.02l-0.07 0c-0.02,0
      -0.03,-0.01 -0.03,-0.02l0 -0.19c0,-0.01 0.01,-0.02 0.03,-0.02z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Battery",
  props: {
    level: {
      type: Number,
      default() {
        return {};
      },
    },
  },
  computed: {
    classBasic: function () {
      return {
        fil4: this.level === 0,
        fil3: this.level >= 1 && this.level <= 24,
        fil2: this.level >= 25 && this.level <= 50,
        fil1: this.level >= 51 && this.level <= 80,
        fil0: this.level >= 81 && this.level <= 100,
      };
    },
    classFirst: function () {
      return {
        fil5: this.level === 0,
        fil3: this.level >= 1 && this.level <= 24,
        fil2: this.level >= 25 && this.level <= 50,
        fil1: this.level >= 51 && this.level <= 80,
        fil0: this.level >= 81 && this.level <= 100,
      };
    },
    classSecond: function () {
      return {
        fil5: this.level === 0 || (this.level >= 1 && this.level <= 24),
        // fil5: this.level >= 1 && this.level <= 24,
        fil2: this.level >= 25 && this.level <= 50,
        fil1: this.level >= 51 && this.level <= 80,
        fil0: this.level >= 81 && this.level <= 100,
      };
    },
    classTrird: function () {
      return {
        fil5:
          this.level === 0 ||
          (this.level >= 1 && this.level <= 24) ||
          (this.level >= 25 && this.level <= 50),
        // fil5: this.level >= 1 && this.level <= 24,
        // fil5: this.level >= 25 && this.level <= 50,
        fil1: this.level >= 51 && this.level <= 80,
        fil0: this.level >= 81 && this.level <= 100,
      };
    },
    classFour: function () {
      return {
        fil5:
          this.level === 0 ||
          (this.level >= 1 && this.level <= 24) ||
          (this.level >= 25 && this.level <= 50) ||
          (this.level >= 51 && this.level <= 80),
        // fil5: this.level >= 1 && this.level <= 24,
        // fil5: this.level >= 25 && this.level <= 50,
        // fil1: this.level >= 51 && this.level <= 80,
        fil0: this.level >= 81 && this.level <= 100,
      };
    },
  },
};
</script>
<style type="text/css">
.fil0 {
  fill: #006633;
}

.fil1 {
  fill: #746cff;
}
.fil2 {
  fill: #ae8cfc;
}
.fil3 {
  fill: #c74c6d;
}
.fil4 {
  fill: white;
}
.fil5 {
  fill: white;
}
</style>

import axios from "axios";
import { getItem } from "@/helpers/persistanceStorage";
import router from "@/router";

// axios.defaults.baseURL = "https://timers.autosystem.su/api/1/";
// axios.defaults.baseURL = "https://www.pharos-nsk.ru/api/1/";
axios.defaults.baseURL = "https://monitoring.glonassdv.ru/api/1"
axios.interceptors.response.use(
    (response) => {
      console.log(response, "response inter");
      if (response.status === 401) {
        // alert("You are not authorized");
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("accessTokenStore");
        localStorage.removeItem("userInfo");
        router.push({ name: "Login" });
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
);
axios.interceptors.request.use((config) => {
  const token = getItem("accessTokenStore");
  const authorizationToken = token ? `${token}` : "";
  if (authorizationToken === "") {
    console.log(config, "config");
  } else {
    config.headers.Authorization = authorizationToken;
  }
  console.log(config);
  return config;
});

export default axios;

<template>
  <div>
    <div id="modalAddBlock" class="modal">
      <div class="modal-content">
        <div class="modal-info__title-block-program mint-green-color">
          <p class="modal-info__title-program">Добавление устройства</p>
          <div class="modal-info__close-program modal-close">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>
      </div>
      <div class="modal-add-block modal-scroll">
        <!-- @change="onChangeSelected" -->
        <div class="modal-add-block__select">
          <select class="icons sidenav__select" v-model="selectBlock">
            <option value="" disabled selected>Выберите тип устройства</option>
            <option value="1" selected>Таймеры</option>

            <!-- <option value="2">Демирование</option> -->
            <option value="3">Трекеры</option>
          </select>
        </div>

        <div class="input-field col s6">
          <p class="modal-add-block__text">Серийный номер</p>
          <input
            id="icon_lat"
            type="text"
            name="serialNumber"
            class="validate"
            v-model="serialNumber"
          />
        </div>
        <div class="modal-add-block__settings" v-if="selectBlock">
          <div class="modal-add-block__settings-title">
            <p class="modal-add-block__title">Настройте новое уcтройство</p>
          </div>

          <div class="" v-if="selectBlock === '1'">
            <p
              class="modal-title__error red-text text-darken-1"
              v-if="this.isError"
            >
              {{ responseAdd }}
            </p>
            <component-settings-timer
              @handleClick="handleClick"
              ref="SettingsTimer"
            />
          </div>
          <div class="" v-if="selectBlock === '2'">
            <!-- <div class="modal-scroll"> -->
            <SettingsAllDemir
              v-for="(div, index) in divs"
              :key="div.id"
              v-bind:item_settings="div"
              v-on:remove="deleteRow(index)"
            />

            <div class="modal-settings__button-add">
              <button
                type="button"
                name="btn_login"
                @click="addRow()"
                class="col s12 btn btn-large waves-effect mint-green-color font"
              >
                Добавить таймер
              </button>
            </div>

            <div class="modal-timer__wrap">
              <p class="modal-title">Изменить интервал:</p>
              <div class="input-field col s6">
                <input
                  id="last_name"
                  type="number"
                  name="intervalUpdateRadioSignal"
                  class="validate"
                  v-model="intervalUpdateRadioSignal"
                  placeholder="1-100"
                />

                <span
                  class="helper-text"
                  data-error="Заполните поле"
                  data-success="Заполнено"
                  >Впишите интервал</span
                >
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="" v-if="selectBlock === '3'">
            <create-new-tracker
              ref="SettingsTracker"
              @successAddTracker="successAddTracker"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          name="btn_login"
          @click="addDevice"
          class="col s12 btn  waves-effect dark-green-color"
        >
          Добавить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import { mapState } from "vuex";
import ComponentSettingsTimer from "./ComponentSettingsTimer.vue";
import SettingsAllDemir from "./SettingsAllDemir.vue";
import CreateNewTracker from "./Tracker/CreateNewTracker.vue";
export default {
  name: "AppModalAddBlock",
  data() {
    return {
      selectBlock: "",
      modalSelect: null,
      modalInstance: null,
      serialNumber: "",
      index: 2,
      isError: false,
      intervalUpdateRadioSignal: "",
      divs: [
        {
          id: 1,
          nameClock: "alarmClockValue1",
          nameClockTitle: "Будильник № 1",
          namePower: "alarmClockPower1",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      responseAdd: (state) => state.lights.responseAdd,
    }),
  },

  methods: {
    handleClick() {
      console.log("handleClick");
      if (this.responseAdd === "ok") {
        this.isError = false;
        const modal = document.getElementById("modalAddBlock");
        this.modalInstance = M.Modal.init(modal, close);
      } else {
        this.isError = true;
      }
    },
    successAddTracker() {
      this.serialNumber = "";
      this.selectBlock = "";
      const modal = document.getElementById("modalAddBlock");
      this.modalInstance = M.Modal.init(modal, close);
    },
    addRow() {
      if (this.divs.length < 6) {
        this.isCountMore = false;
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      } else {
        console.log("Добавлено уже более 6");
        this.isCountMore = true;
      }
    },
    deleteRow(index) {
      this.index--;
      for (var i = 0; i < this.divs.length; i++) {
        if (this.divs[i].id > this.divs[index].id) {
          this.divs[i].id = this.divs[i].id - 1;
          this.divs[i].nameClock = "alarmClockValue" + this.divs[i].id;
          this.divs[i].namePower = "alarmClockPower" + this.divs[i].id;
          (this.divs[i].nameClockTitle = "Будильник № " + this.divs[i].id),
            console.log(this.divs[i]);
        }
      }
      this.divs.splice(index, 1);
    },
    addDevice() {
      if (this.selectBlock === "1") {
        this.$refs.SettingsTimer.submitData(this.serialNumber);
      } else if (this.selectBlock === "3") {
        this.$refs.SettingsTracker.submitData(this.serialNumber);
      }
    },
  },
  components: {
    ComponentSettingsTimer,
    SettingsAllDemir,
    CreateNewTracker,
  },

  mounted() {
    const modal = document.getElementById("modalAddBlock");
    this.modalInstance = M.Modal.init(modal);
    var elems = document.querySelectorAll("select");
    this.modalSelect = M.FormSelect.init(elems);
  },
};
</script>
<style scoped>
.modal {
  width: 35% !important;
  border-radius: 8px !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  //height: initial !important;
  border-radius: 8px;
}
.font {
  font-weight: 500;
}
.message {
  font-size: 16px;
}
.input-field {
  margin-top: 0.5rem !important ;
}
</style>

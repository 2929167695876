import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
import lights from "@/store/modules/lights";
import history from "@/store/modules/history";
import demir from "@/store/modules/demir";
import socket from "@/store/modules/socket";
import trackers from "@/store/modules/trackers";
import download from "@/store/modules/download";
import allModal from  "@/store/modules/all-modal"
import route from  "@/store/modules/route"

Vue.use(Vuex);
// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {
//     auth,
//     lights,
//     history,
//     demir,
//     socket,
//     trackers,
//     download,
//     allModal,
//   },
// });
const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    lights,
    history,
    demir,
    socket,
    trackers,
    download,
    allModal,
    route
  },
});

export default store;

<template>
  <div>
    <div class="modal-info__wrap-margin no-margin">
      <p class="title-mini index-color-text">Журнал изменений устройства</p>
    </div>
    <div class="view-tab">
<!--      <div class="row">-->
<!--        <div class="col s12">-->
<!--          <ul class="tabs">-->
<!--            <li class="tab col s6 view-tab__text waves-effect" @click="switchTab('active')" :class="{ 'active-tab': activeTab === 'active' }">Активные</li>-->
<!--            <li class="tab col s6 view-tab__text waves-effect" @click="switchTab('completed')" :class="{ 'active-tab': activeTab === 'completed' }">Завершенные</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->

      <!-- Активные записи -->
      <div v-show="activeTab === 'active'" >
        <table v-if="allCommandSetting && allCommandSetting.activeUserCommands.length !== 0">
          <thead>
          <tr>
            <th>Дата создания</th>
            <th style="text-align: center !important;">Команда</th>
            <th style="text-align: center !important;">Статус</th>
<!--            <th></th>-->
          </tr>
          </thead>
          <app-body-table-active-log v-for="(item,index) in allCommandSetting.activeUserCommands" :key="index" :item_active="item"/>
        </table>
        <empty-logs text="На данный момент активных записей не найдено" v-else/>
      </div>

      <!-- Завершенные записи -->
<!--      <div v-show="activeTab === 'completed'" >-->
<!--        <table v-if="allCommandSetting && allCommandSetting.completeUserCommands.length !== 0">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>Дата завершения</th>-->
<!--            <th>Команда</th>-->
<!--&lt;!&ndash;            <th>Значение</th>&ndash;&gt;-->
<!--            <th>Статус</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <app-body-table-active-log v-for="(item,index) in allCommandSetting.completeUserCommands" :key="index" :item_active="item"/>-->
<!--        </table>-->
<!--        <empty-logs text="На данный момент завершенных записей не найдено" v-else/>-->
<!--      </div>-->

    </div>
  </div>
</template>


<script>
import EmptyLogs from "../Report/EmptyLogs.vue";
import M from 'materialize-css';
import axios from 'axios';
import { mapState } from 'vuex';
import AppBodyTableActiveLog from '@/components/Tracker/BodyTableItemActiveLog.vue';

export default {
  data() {
    return {
      activeTab: 'active',
      elems: null,
      allCommandSetting: {
        activeUserCommands: [],
        completeUserCommands: []
      }
    };
  },
  components: { AppBodyTableActiveLog, EmptyLogs },
  name: "AppActiveChangeLog",
  methods: {
    switchTab(tab) {
      this.activeTab = tab;
    },
    fetchCommands() {
      axios.get('/get/glonass/commands', {
        params: { serial: this.itemTracker.serial }
      })
          .then(response => {
            this.allCommandSetting = {
              activeUserCommands: response.data.filter(command => this.isActive(command)),
              completeUserCommands: response.data.filter(command => !this.isActive(command))
            };
          })
          .catch(error => {
            console.error('Error fetching commands', error);
            this.allCommandSetting = {
              activeUserCommands: [],
              completeUserCommands: []
            };
          });
    },
    isActive(command) {
      return command.status !== 'COMPLETED';
    }
  },
  computed: {
    ...mapState({
      itemTracker: (state) => state.trackers.itemTracker,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.elems = document.querySelectorAll(".tabs");
      M.Tabs.init(this.elems);
    });

    this.fetchCommands();
  }
};
</script>


<style scoped>
table {
  font-size: 12px !important;
}
.no-margin {
  margin: 0 !important;
}
</style>

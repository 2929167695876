<template>
  <tbody>
  <tr>
    <td>{{item_active.commandTimestamp}}</td>
<!--    <td v-if="item_active.status === 'COMPLETE'">{{formattedEndDateTime}}</td>-->

    <td style="text-align: center !important;">{{ getCommandName(item_active.commandType) }}</td>
<!--    <td>{{item_active.commandValue}}</td>-->
    <td class="table-report__td-center" v-if="item_active.status === 'ACTIVE'">
      <div class="table-status orange ">
        <p class="table-status__text white-text">Ожидание</p>
        <div class="table-status__icon">
       <img src="../../assets/svg/network-activity.svg" alt="wait">
        </div>
      </div>
    </td>
    <td class="table-report__td-center" v-else>
      <div class="table-status success">
        <p class="table-status__text white-text">Выполнена</p>
        <div class="table-status__icon">
          <i class="material-icons white-text">done</i>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>

  import dayjs from 'dayjs';
  import commandDictionary from '@/helpers/glossaryCommand'

  export default {
    name: "AppBodyTableActiveLog",
    computed: {
      formattedDateTime() {
        return  dayjs(this.item_active.createDate).format('DD MMMM YYYY, HH:mm:ss');
      },
      formattedEndDateTime() {
        return  dayjs(this.item_active.completeDate).format('DD MMMM YYYY, HH:mm:ss');
      },
    },
    methods: {
      getCommandName(commandNumber) {
        console.log(commandNumber);
        return commandDictionary[commandNumber] || 'Произвольная команда';
      }
    },
    props: {
      item_active: {
        type: Object,
        default() {
          return {};
        },
      },
    }
  };
</script>


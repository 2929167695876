<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalTrackerProperty" class="modal">
      <div id="modalTracker" action="">
        <div class="modal-content" v-if="itemTracker !== null">
          <div class="modal-info__title-block index-color">
            <div class="modal-info__wrap-title">
              <img
                src="../../assets/svg/car.svg"
                alt="car"
                class="shema-block__img-svg"
              />
              <div class="modal-info__title">
                <p class="title-mini white-text">
                  Свойства: {{ itemTracker.name }}
                </p>
              </div>
            </div>
<!--            modal-close-->
            <div class="modal-info__close" id="closeBtn" @click="closeModal()">
              <i class="material-icons white-text"> close</i>
            </div>
          </div>

            <div class="spinner-container" v-if="isLoadingSend || isLoadingChangeName">
              <div class="spinner-overlay"></div>
              <div class="spinner">
                <div class="spinner-icon"></div>
              </div>
            </div>

          <div class="modal-info__info" >
            <div class="row modal-info__wrap-info">
              <div class="row modal-form col s6 modal-scroll-max" v-if="isOpenCommand">

                <AppArbitraryCommand  :normalizedTracker="itemTracker"/>
              </div>
              <div class="row modal-form col s12 modal-scroll-max" v-if="!isOpenCommand && itemTracker.gprsResponse">
                <general-properties
                  ref="generalProperties"
                  :normalizedTracker="itemTracker"
                />

                <settings-tracker
                  ref="settingsTracker"
                  @openModalWar="openModalWar"
                  :normalizedTracker="itemTracker"
                />
              </div>
              <div class="row modal-form col s6 modal-scroll-max waiting" v-if="!itemTracker.gprsResponse && !isOpenCommand ">

                <div class="waiting__img">
                  <img src="../../assets/svg/network-activity.svg" alt="waiting">
                </div>
                <div class="waiting__text">
                  <p>Ожидаем сообщение с устройства</p>
                </div>

              </div>

            </div>

          </div>

          <div class="modal-info__footer">
            <div class="modal-info__footer-tab-settings">
            <div class="modal-info__footer-wrap">

<!--                <button-->
<!--                    v-if="!isOpenCommand"-->
<!--                    type="button"-->
<!--                    @click="openViewCommand"-->
<!--                    name="btn_login"-->
<!--                    class="col s12 btn waves-effect dark-green-color"-->
<!--                >-->
<!--                  Произвольные команды-->
<!--                </button>-->
<!--              <button-->
<!--                 v-else-->
<!--                  type="button"-->
<!--                  @click="openViewCommand"-->
<!--                  name="btn_login"-->
<!--                  class="col s12 btn waves-effect dark-green-color"-->
<!--              >-->
<!--                Вернуться к основным настройкам-->
<!--              </button>-->
            </div>
            </div>
            <div class="modal-info__footer-tab-submit">
              <div class="modal-info__footer-wrap">
              <button
                  type="button"
                  name="btn_login"
                  class="modal-close col s12 btn black-text waves-effect gray-color"
              >
                Отменить
              </button>
            </div>
            <div class="modal-info__footer-wrap">
              <button
                  type="button"
                  @click="updateProperty()"
                  name="btn_login"
                  class="col s12 btn waves-effect waves-light index-color"
              >
                Сохранить
              </button>
            </div>
            </div>
          </div>
        </div>
          <AppModalClose @closeModalClose="closeModalClose" @allModalClose="allModalClose"/>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";


import GeneralProperties from "./GeneralProperties.vue";
import SettingsTracker from "./SettingsTracker.vue";
import { mapState } from 'vuex'
import { actionTypesModal } from '@/store/modules/all-modal'
import AppArbitraryCommand from '@/components/Tracker/ArbitraryCommand.vue'
import AppModalClose from '@/components/ModalClose.vue'
// import trackers from '@/store/modules/trackers'
export default {
  name: "AppModalTrackerProperty",
  data() {
    return {
      modalInstance: null,
      modalClose: null,
      selectedTimezone: null,
      newName: null,
      isOpenCommand: false,
    };
  },
  components: { AppModalClose, AppArbitraryCommand,  GeneralProperties, SettingsTracker },
  computed: {
    ...mapState({
      itemTracker: (state) => state.trackers.itemTracker,
      isLoadingSend: (state) =>  state.trackers.isLoadingSend,
      isLoadingChangeName: (state) => state.trackers.isLoadingChangeName
    }),
  },
  methods: {
    updateProperty() {
      this.$refs.generalProperties.updateNewSettings();
      this.$refs.settingsTracker.updateNewSettings();
    },
    openModalWar(bool) {
      console.log(bool, 'передал')
      // this.$el.querySelector("#closeBtn").classList.remove("modal-close");
      if (bool) {
        console.log('Есть изменения')
        this.modalClose.open();
      } else {
        this.$store.dispatch(actionTypesModal.closeModalTracker)
      }
    },
    closeModalClose() {
      this.modalClose.close();
    },
    allModalClose() {
      this.modalClose.close();
      this.$store.dispatch(actionTypesModal.closeModalTracker)
    },
    closeModal() {
      this.$store.dispatch(actionTypesModal.closeModalTracker)
      // this.$refs.settingsTracker.closeModal();
    },
    openViewCommand() {
      this.isOpenCommand = !this.isOpenCommand
    }
  },
  mounted() {
    this.modalClose = M.Modal.init(this.$el.querySelector("#modalClose"));
    M.updateTextFields();
  },
};
</script>

<style scoped>
.modal {
  width: 45% !important;
  height: auto !important;
}
 .modal {
  top: 2% !important;
  max-height: 100vh !important;
}

.modal-scroll-max {
   max-height: 65vh !important;
 }

/*.modal-content {
  overflow-y: auto !important;
} */

/*.modal-form {
  height: 100% !important;
}*/

.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}
</style>

import M from "materialize-css";

const state = {
  modalTrackerProperty: null,
  modalDeleteTracker: null,
  modalControlTracker: null, // Добавил новое свойство состояния
  openCardRouting: false,
};

export const mutationTypesModal = {
  setModalTrackerProperty: "[allModal] setModalTrackerProperty",
  deleteModal: "[allModal] deleteModal",
  setModalControlTracker: "[allModal] setModalControlTracker", // Добавил новый тип мутации
  setModalRouting: "[allModal] setModalRouting",
};

export const actionTypesModal = {
  openModalTracker: "[allModal] openModalTracker",
  openModalTrackerControl: "[allModal] openModalTrackerControl",
  closeModalTracker: "[allModal] closeModalTracker",
  openModalDeleteTracker: "[allModal] openModalDeleteTracker",
  closeModalDeleteTracker: "[allModal] closeModalDeleteTracker",
  openModalRouting: "[allModal] openModalRouting",
  closeModalRouting: "[allModal] closeModalRouting",
  closeModalTrackerControl: "[allModal] closeModalTrackerControl",

};

const mutations = {
  [mutationTypesModal.setModalTrackerProperty](state, modalTr) {
    state.modalTrackerProperty = modalTr;
  },
  [mutationTypesModal.setModalControlTracker](state, modalCtrl) { // Добавил новый мутационный метод
    state.modalControlTracker = modalCtrl;
  },
  [mutationTypesModal.setModalRouting](state, isCardRout) {
    state.openCardRouting = isCardRout;
  },
  [mutationTypesModal.deleteModal](state, modalDelTr) {
    state.modalDeleteTracker = modalDelTr;
  },
};

const actions = {
  [actionTypesModal.openModalTracker]({ commit }) {
    console.log("Я открыл 1");
    const modalInstance = M.Modal.init(
        document.querySelector("#modalTrackerProperty")
    );
    commit(mutationTypesModal.setModalTrackerProperty, modalInstance);
    modalInstance.open(); // Открыть модальное окно
  },
  [actionTypesModal.closeModalTracker]({ commit }) {
    console.log("Я закрыл 1");
    if (state.modalTrackerProperty) {
      state.modalTrackerProperty.close(); // Закрыть модальное окно
    }
    commit(mutationTypesModal.setModalTrackerProperty, null);
  },
  [actionTypesModal.openModalTrackerControl]({ commit }) {
    console.log("Я открыл Control");
    const modalInstance = M.Modal.init(
        document.querySelector("#modalControlTracker") // Изменил селектор на новое модальное окно
    );
    commit(mutationTypesModal.setModalControlTracker, modalInstance);
    modalInstance.open(); // Открыть модальное окно
  },
  [actionTypesModal.closeModalTrackerControl]({ commit }) {
    console.log("Я закрыл Control");
    if (state.modalControlTracker) { // Изменил проверку на новое модальное окно
      state.modalControlTracker.close(); // Закрыть модальное окно
    }
    commit(mutationTypesModal.setModalControlTracker, null); // Обновил мутацию
  },
  [actionTypesModal.openModalDeleteTracker]({ commit }) {
    console.log("Я открыл 2");
    const modalInstance = M.Modal.init(
        document.querySelector("#modalDeleteTracker")
    );
    commit(mutationTypesModal.deleteModal, modalInstance);
    modalInstance.open(); // Открыть модальное окно
  },
  [actionTypesModal.closeModalDeleteTracker]({ commit }) {
    console.log("Я закрыл 2");
    if (state.modalDeleteTracker) {
      state.modalDeleteTracker.close(); // Закрыть модальное окно
    }
    commit(mutationTypesModal.deleteModal, null);
  },
  // routing
  [actionTypesModal.openModalRouting]({ commit }) {
    console.log("Я открыл 3");
    commit(mutationTypesModal.setModalRouting, true);
  },

  [actionTypesModal.closeModalRouting]({ commit }) {
    console.log("Я закрыл 3");
    commit(mutationTypesModal.setModalRouting, false);
  },
};

export default {
  state,
  mutations,
  actions,
};

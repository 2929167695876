import axios from "@/api/axios";

const getRoute = (data) => {
  return axios.post(`/get/coordinates`, data);
      // ?serial=${data.serial}&startDate=${data.startDate}&endDate=${data.endDate}
};

export default {
  getRoute,
};

<template>
  <ul class="validate">
    <li class="validate__info">
      *{{ validationErrors.message }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "AppValidationErrors",
  props: {
    validationErrors: {
      type: Object,
      required: true, //без него ничего не будет работать
    },
  },
  computed: {},
};
</script>

<template>
  <div class="report-empty__body">
    <div class="report-empty__svg-wrap">
      <img
        src="../../assets/svg/folder.svg"
        alt="report"
        class="report-empty__svg"
      />
    </div>
    <div class="report-empty__text">
      <p class="w500">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppEmptyReport",
  props: {
    text: {
      type: String,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>

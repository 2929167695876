import trackersAPi from "@/api/trackers.js";

const state = {
  isLoading: false, //ожидание запроса
  isLoadingSend: false, // ожидание отправки команд
  isLoadingChangeName: false, // ожидание отправки изменения имени

  allTrackers: null,
  itemTracker: null,
  allCommandSetting: null,
  checkedTrackers: [],
  changeJournal:  null,
  allReportsTr: null,
  logsByDate: null,
};

export const mutationTypes = {
  getListStart: "[tracker] getListStart",
  getListSuccess: "[tracker] getListSuccess",
  getListFailure: "[tracker] getListFailure",


  deleteTrackerStart:  "[tracker] deleteTrackerStart",
  deleteTrackerSuccess: "[tracker]  deleteTrackerSuccess",
  deleteTrackerFailure: "[tracker]  deleteTrackerFailure",

  safeItemTrackerSuccess: "[tracker] safeItemTrackerSuccess",


  updateItemListStart: "[tracker] updateItemListStart",
  updateItemListSuccess: "[tracker] updateItemListSuccess",
  updateItemListFailure: "[tracker] updateItemListFailure",

  getAllReportsTrackersStart: "[tracker]   getAllReportsTrackersStart",
  getAllReportsTrackersSuccess: "[tracker]   getAllReportsTrackersSuccess",
  getAllReportsTrackersFailure: "[tracker]   getAllReportsTrackersFailure",

  getLogsByDateStart: "[tracker]    getLogsByDateStart",
  getLogsByDateSuccess: "[tracker]    getLogsByDateSuccess",
  getLogsByDateFailure: "[tracker]    getLogsByDateFailure",

  deleteReportTrackerStart: "[tracker]   deleteReportTrackerStart",
  deleteReportTrackerSuccess: "[tracker]   deleteReportTrackeruccess",
  deleteReportTrackerFailure: "[tracker]   deleteReportTrackerFailure",

  addTrackerStart: "[tracker]    addTrackerStart",
  addTrackerSuccess: "[tracker]    addTrackerSuccess",
  addTrackerFailure: "[tracker]    addTrackerFailure",

  sendCommandsStart: "[tracker]    sendCommandsStart",
  sendCommandsSuccess: "[tracker]    sendCommandSuccess",
  sendCommandsFailure: "[tracker]    sendCommandsFailure",

  getAllCommandsStart: "[tracker]    getAllCommandsStart",
  getAllCommandsSuccess: "[tracker]    getAllCommandsSuccess",
  getAllCommandsFailure: "[tracker]    getAllCommandsFailure",

  changeNameTrackerStart: "[tracker] changeNameTrackerStart",
  changeNameTrackerSuccess: "[tracker]   changeNameTrackerSuccess",
  changeNameTrackerFailure: "[tracker]   changeNameTrackerFailure",

  submitNewCommandStart: "[tracker]  submitNewCommandStart",
  submitNewCommandSuccess: "[tracker]    submitNewCommandSuccess",
  submitNewCommandFailure: "[tracker]    submitNewCommandFailure",

  checkedTrackerSuccess: "[tracker] checkedTrackerSuccess",



};
export const actionTypesTracker = {
  getList: "[tracker] getList",

  deleteTracker: "[tracker]  deleteTracker",

  safeItemTracker: "[tracker] safeItemTracker",

  updateItemList: "[tracker] updateItemList",

  checkedTracker: "[tracker] checkedTracker",

  getAllReportsTrackers: "[tracker]   getAllReportsTrackers",

  getLogsByDate: "[tracker] getLogsByDate",

  deleteReportTracker: "[tracker] deleteReportTracker",

  addTracker: "[tracker] addTracker",

  sendCommands: "[tracker] sendCommands",

  getAllCommands: "[tracker] getAllCommands",

  changeNameTracker: "[tracker] changeNameTracker",

  submitNewCommand: "[tracker] submitNewCommand"

};

const mutations = {
  //Получение блоков
  [mutationTypes.getListStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getListSuccess](state, items) {
    state.isLoading = false;
    state.allTrackers = items;
  },
  [mutationTypes.getListFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTrackerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteTrackerSuccess](state, allTrackers) {
    state.allTrackers = allTrackers
    state.isLoading = false;
  },
  [mutationTypes.deleteTrackerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.safeItemTrackerSuccess](state,item) {
    state.itemTracker = item;
  },

  [mutationTypes.changeNameTrackerStart](state) {
    state.isLoadingChangeName = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeNameTrackerSuccess](state) {
    state.isLoadingChangeName = false;
  },
  [mutationTypes.changeNameTrackerFailure](state, payload) {
    state.isLoadingChangeName = false;
    state.validationErrors = payload;
  },
  //Обновление информации блока
  [mutationTypes.updateItemListStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.updateItemListSuccess](state, items) {
    state.isLoading = false;
    state.newItemTracker = items;
  },
  [mutationTypes.updateItemListFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //получение всех созданных отчетов по маякам
  [mutationTypes.getAllReportsTrackersStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getAllReportsTrackersSuccess](state, items) {
    state.isLoading = false;
    state.allReportsTr = items;
  },
  [mutationTypes.getAllReportsTrackersFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //получение логов за определенный промеэуток времени
  [mutationTypes.getLogsByDateStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getLogsByDateSuccess](state, items) {
    state.isLoading = false;

    state.logsByDate = items;

  },
  [mutationTypes.getLogsByDateFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Удаления отчета

  [mutationTypes.deleteReportTrackerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteReportTrackerSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteReportTrackerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // Добавление трекера
  [mutationTypes.addTrackerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.addTrackerSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.addTrackerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // Отправка команд изменения параметров настройки трекера

  [mutationTypes.sendCommandsStart](state) {
    state.isLoadingSend = true;
    state.validationErrors = null;
  },
  [mutationTypes.sendCommandsSuccess](state, beaconItem) {
    state.isLoadingSend = false;
    state.itemTracker = beaconItem
    // Находим индекс элемента в массиве allTrackers
    const index = state.allTrackers.findIndex(
        (item) => item.beaconId === beaconItem.beaconId
    );

    // Если элемент найден, заменяем его новым элементом
    if (index !== -1) {

      state.allTrackers.splice(index, 1, beaconItem);
      console.log(state.allTrackers.splice(index, 1, beaconItem))

    } else {
      // Если элемент не найден, добавляем его в массив
      // state.allResults.push(newResultItem);
    }

  },
  [mutationTypes.sendCommandsFailure](state, payload) {
    state.isLoadingSend = false;
    state.validationErrors = payload;
  },
  //отображение на карте машин
  [mutationTypes.checkedTrackerSuccess](state, payload) {
    state.checkedTrackers = payload;
  },

  // getAllCommands
  // Получение всего журнала изменений
  [mutationTypes.getAllCommandsStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getAllCommandsSuccess](state, allCommandSetting) {
    state.isLoading = false;
    state.allCommandSetting = allCommandSetting
  },
  [mutationTypes.getAllCommandsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  // Отправка произвольной команды
  [mutationTypes.submitNewCommandStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.submitNewCommandSuccess](state) {
    state.isLoading = false;

  },
  [mutationTypes.submitNewCommandFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesTracker.getList](context) {
    return new Promise((resolve) => {
      console.log('getListStart')
      context.commit(mutationTypes.getListStart);
      trackersAPi
        .getAllTracker()
        .then((response) => {
          // if (response.data.status === "SUCCESS") {
          console.log('getList')
          context.commit(mutationTypes.getListSuccess, response.data);

          // } else {
          //   context.commit(mutationTypes.getListFailure, response.data);
          // }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },
  [actionTypesTracker.deleteTracker](context, serial) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteTrackerStart);
      trackersAPi
          .deleteTracker(serial)
          .then((response) => {
            context.commit(mutationTypes.deleteTrackerSuccess, response.data);
            resolve(response.data);
          })
          .catch((response) => {
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesTracker.safeItemTracker](context,item) {
    context.commit(mutationTypes.safeItemTrackerSuccess, item);
  },
  [actionTypesTracker.updateItemList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.updateItemListStart);
      trackersAPi
        .updateItemList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.updateItemListSuccess,
              response.data.result
            );
          } else {
            context.commit(mutationTypes.updateItemListFailure, response.data);
          }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },

  [actionTypesTracker.getAllReportsTrackers](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllReportsTrackersStart);
      trackersAPi
        .getAllReportsTrackers()
        .then((response) => {
          context.commit(
            mutationTypes.getAllReportsTrackersSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
          context.commit(
            mutationTypes.getAllReportsTrackersFailure,
            response.data
          );
        });
    });
  },

  [actionTypesTracker.getLogsByDate](context, info) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getLogsByDateStart);
      trackersAPi
        .getLogsByDate(info)
        .then((response) => {
          context.commit(mutationTypes.getLogsByDateSuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
          context.commit(mutationTypes.getLogsByDateFailure, response.data);
        });
    });
  },
  // deleteReportTracker
  [actionTypesTracker.deleteReportTracker](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteReportTrackerStart);
      trackersAPi
        .deleteReportTracker(id)
        .then((response) => {
          context.commit(
            mutationTypes.deleteReportTrackerSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
          context.commit(
            mutationTypes.deleteReportTrackerFailure,
            response.data
          );
        });
    });
  },
  //
  [actionTypesTracker.addTracker](context, info) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addTrackerStart);
      trackersAPi
        .addTracker(info)
        .then((response) => {
          context.commit(mutationTypes.addTrackerSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
          context.commit(mutationTypes.addTrackerFailure, response.data);
        });
    });
  },
  [actionTypesTracker.sendCommands](context, info) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.sendCommandsStart);
      trackersAPi
          .sendCommands(info)
          .then((response) => {
            context.commit(mutationTypes.sendCommandsSuccess, response.data);
            resolve(response.data);
          })
          .catch((response) => {
            console.log("ошибка", response);
            context.commit(mutationTypes.sendCommandsFailure, response.data);
          });
    });
  },
  //
  [actionTypesTracker.getAllCommands](context, info) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllCommandsStart);
      trackersAPi
          .getAllCommands(info)
          .then((response) => {
            context.commit(mutationTypes.getAllCommandsSuccess, response.data);
            resolve(response.data);
          })
          .catch((response) => {
            console.log("ошибка", response);
            context.commit(mutationTypes.getAllCommandsFailure, response.data);
          });
    });
  },
  //
  [actionTypesTracker.changeNameTracker](context, name) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeNameTrackerStart);
      trackersAPi
          .changeNameTracker(name)
          .then((response) => {
            context.commit(mutationTypes.changeNameTrackerSuccess, response.data);
            resolve(response.data);
          })
          .catch((response) => {
            console.log("ошибка", response);
            context.commit(mutationTypes.changeNameTrackerFailure, response.data);
          });
    });
  },
  //
  [actionTypesTracker.submitNewCommand](context, info) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.submitNewCommandStart);
      trackersAPi
          .submitNewCommand(info)
          .then((response) => {
            context.commit(mutationTypes.submitNewCommandSuccess);
            resolve(response.data);
          })
          .catch((response) => {
            console.log("ошибка", response);
            context.commit(mutationTypes.submitNewCommandFailure, response.data);
          });
    });
  },
  [actionTypesTracker.checkedTracker](context, credentials) {
    context.commit(mutationTypes.checkedTrackerSuccess, credentials);
  },
};
export default {
  state,
  mutations,
  actions,
};

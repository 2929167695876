<template>
  <div class="create-report">
    <div class="list-items-block col s3 full-width" v-if="trackers">
      <div class="breadcrump" @click="closeCreate">
        <div class="breadcrump__arrow">
          <img src="../../assets/svg/back-arrow.svg" alt="arrow">
        </div>
        <div class="breadcrump__text">
          <p>Назад</p>
        </div>

      </div>
      <div class="create-report__title">
        <p class="title-mini index-color-text">Выберите устройство</p>
      </div>
      <div class="divider"></div>
      <item-tracker-mini
        v-for="(item, index) in trackers"
        :key="item.id"
        :index="index"
        :item_tracker="item"
        v-model="checkedTracker"
      />
      <!-- <div class="create-report__checkbox-all index-color">
        <p>
          <label>
            <input
              type="checkbox"
              class="filled-in"
              v-model="selectAllTracker"
            />

            <span class="title-text">Выбрать все</span>
          </label>
        </p>
      </div> -->
    </div>
    <div class="create-report__period col s9 full-width">
      <div class="create-report__title">
        <p class="title-mini index-color-text">Bыберите период</p>
      </div>

      <div class="time-zone">
        <div class="time-zone__wrap">
          <p>Дата начала периода:</p>
          <date-picker
            v-model="startDate"
            type="datetime"
            placeholder="Выберите период"
            class="time-zone__time"
          ></date-picker>
        </div>
        <div class="time-zone__wrap">
          <p>Дата конца периода:</p>
          <date-picker
            v-model="endDate"
            type="datetime"
            placeholder="Выберите период"
            class="time-zone__time"
          ></date-picker>
        </div>
      </div>

      <div class="report__btn-create right">
        <a

          :class="{ disabled: this.startDate === '' || this.endDate === '' || this.checkedTracker === null }"
          class="waves-effect waves-light btn btn-large dark-green-color"
          @click="getReportId"
          ><i class="material-icons left">folder</i> Создать отчет</a
        >
      </div>

      <div class="" v-if="logsByDate">
        <div class="report-logs" v-if="logsByDate.logResponses !== null">
          <div class="table-report__btn right">
            <a
              class="waves-effect waves-light btn btn-large success"
              @click="downloadReportId"
              ><i class="material-icons left">file_download</i> Скачать
              документ в EXCEL</a
            >
          </div>
          <div class="table-report__btn right">
            <a
                class="waves-effect waves-light btn btn-large success"
                @click="downloadReportIdPdf"
            ><i class="material-icons left">file_download</i> Скачать
              документ в PDF</a
            >
          </div>
          <table-logs />
        </div>
        <empty-logs
          v-if="logsByDate.logResponses === null"
          text="На данный момент записей не найдено"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ItemTrackerMini from "./Trackers/ItemTrackerMini.vue";
import { actionTypesTracker } from "@/store/modules/trackers";
import TableLogs from "./TableReport/TableLogs.vue";
import EmptyLogs from "./EmptyLogs.vue";
import { actionTypesDownload } from "@/store/modules/download";

export default {
  components: { ItemTrackerMini, TableLogs, EmptyLogs },
  name: "AppCreateNewReport",
  data() {
    return {
      checkedTracker: null,
      startDate: "",
      endDate: "",

    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.trackers.isLoading,
      trackers: (state) => state.trackers.allTrackers,
      logsByDate: (state) => state.trackers.logsByDate,
    }),
    disableBtn() {
      return {
        disabled:
          this.startDate === "" &&
          this.endDate === "" &&
          this.checkedTracker === {},
      };
    },
    //Использовать если отчет будет формироваться из нескольких файлов
    // selectAllTracker: {
    //   get: function () {
    //     return this.trackers
    //       ? this.checkedTracker.length == this.trackers.length
    //       : false;
    //   },
    //   set: function (value) {
    //     var checked = [];

    //     if (value) {
    //       this.trackers.forEach(function (trackers) {
    //         checked.push(trackers);
    //       });
    //     }

    //     this.checkedTracker = checked;
    //     this.$store.dispatch(
    //       actionTypesTracker.checkedTracker,
    //       this.checkedTracker
    //     );
    //   },
    // },
  },
  methods: {
    getReportId() {
      console.log('Данные на отправку', this.startDate, this.endDate)
      this.$store.dispatch(actionTypesTracker.getLogsByDate, {
        startDate: this.startDate,
        endDate: this.endDate,
        beaconId: this.checkedTracker.beaconId,
      });
    },
    closeCreate() {
      this.$emit('closeCreate')
    },
    downloadReportId() {
      this.$store
        .dispatch(actionTypesDownload.downloadReport, {
          startDate: this.startDate,
          endDate: this.endDate,
          serial: this.checkedTracker.serial,
          datePositioning: this.logsByDate.datePositioning
        })
        .then((reportData) => {
          const { url, fileName } = reportData;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error("Failed to fetch report: ", error);
        });
    },
    downloadReportIdPdf() {
      this.$store
          .dispatch(actionTypesDownload.downloadReportPdf, {
            startDate: this.startDate,
            endDate: this.endDate,
            serial: this.checkedTracker.serial,
            datePositioning: this.logsByDate.datePositioning
          })
          .then((reportData) => {
            const { url, fileName } = reportData;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.error("Failed to fetch report: ", error);
          });
    }
  },
};
</script>

<style></style>

<template>
  <tbody>
    <tr>
      <td class="table-report__td-center table-report__text-and-description ">
        <div class="table-download waves-effect waves-teal input-margin success " @click="downloadReport">
          <p class="table-download__text white-text">Скачать в EXCEL</p>
          <div class="table-download__icon">
            <i class="material-icons white-text">arrow_downward</i>
          </div>
        </div>
        <div class="table-download waves-effect waves-teal input-margin blue-light" @click="downloadReportPdf">
          <p class="table-download__text">Скачать в PDF</p>
          <div class="table-download__icon">
            <i class="material-icons">arrow_downward</i>
          </div>
        </div>
      </td>
      <td>
        <div class="table-report__text-and-description">
          <p class="table-text__title">{{ item_report.dateCreate }}</p>
          <p class="table-text__dec">{{ item_report.timeCreate }}</p>
        </div>
      </td>

      <td>
        <div class="table-report__text-and-description">
          <p class="table-text__title">{{ formatterStartDate }}</p>
          <p class="table-text__title">{{ formatterEndDate }}</p>
        </div>
      </td>
<!--      table-report__td-center-->
      <td class="">
        <div class="table-status success">
          <p class="table-status__text white-text">Готов</p>
          <div class="table-status__icon">
            <i class="material-icons white-text">done</i>
          </div>
        </div>
      </td>
      <td class="">
        <a class="btn-floating btn-large grey lighten-1" @click="deleteReport()"
          ><i class="material-icons text-white">delete</i></a
        >
      </td>
    </tr>
  </tbody>
</template>

<script>
import dayjs from "dayjs";
import { actionTypesTracker } from "@/store/modules/trackers";
import { actionTypesDownload } from '@/store/modules/download'

export default {
  name: "AppTableBodyReport",

  props: {
    item_report: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    formatterStartDate() {
      return dayjs(this.item_report.startDate).format("YYYY-MM-DD HH:mm:ss");
    },
    formatterEndDate() {
      return dayjs(this.item_report.endDate).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    deleteReport() {
      this.$store
        .dispatch(
          actionTypesTracker.deleteReportTracker,
          this.item_report.reportId
        )
        .then(() => {
          this.$store.dispatch(actionTypesTracker.getAllReportsTrackers);
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    downloadReport() {
      this.$store
          .dispatch(actionTypesDownload.downloadReport, {
            startDate: this.item_report.startDate,
            endDate: this.item_report.endDate,
            serial: this.item_report.serial,
            datePositioning: this.item_report.datePositioning
          })
          .then((reportData) => {
            const { url, fileName } = reportData;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.error("Failed to fetch report: ", error);
          });
    },
    downloadReportPdf() {
      this.$store
          .dispatch(actionTypesDownload.downloadReportPdf, {
            startDate: this.item_report.startDate,
            endDate: this.item_report.endDate,
            serial: this.item_report.serial,
            datePositioning: this.item_report.datePositioning
          })
          .then((reportData) => {
            const { url, fileName } = reportData;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.error("Failed to fetch report: ", error);
          });
    }
  },
};
</script>

<style></style>

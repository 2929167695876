<template>
  <div class="report-empty__body">
    <div class="report-empty__svg-wrap">
      <img
        src="../../assets/svg/folder.svg"
        alt="report"
        class="report-empty__svg"
      />
    </div>
    <div class="report-empty__text">
      <p class="title-mini">На данный момент отчётов не найдено</p>
    </div>
    <div class="report-empty__btn-create center">
      <a
        class="waves-effect waves-light btn btn-large dark-green-color"
        @click="$emit('createNewReport')"
        ><i class="material-icons left">add</i> Создать новый отчет</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "AppEmptyReport",
};
</script>

<style></style>

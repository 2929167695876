<template>
  <div class="table-report">
    <table class="table-report__border striped centered">
      <thead>
        <tr>
          <th>Серийный номер</th>
          <th>Координаты</th>
          <th>Скорость</th>
          <th>Температура</th>
          <th>Заряд батареи</th>
          <th>Режим работы</th>
        </tr>
      </thead>

      <table-item-log v-for="log in logsByDate.logResponses" :key="log.id" :item_log="log" />
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableItemLog from "./TableItemLog.vue";

export default {
  components: { TableItemLog },
  name: "AppTableLogs",
  computed: {
    ...mapState({
      trackers: (state) => state.trackers.allTrackers,
      logsByDate: (state) => state.trackers.logsByDate,
    }),
  },
  methods: {},
};
</script>

<style></style>

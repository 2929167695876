<template>
  <div class="row">
    <div class="col right s12 m12">
      <!-- Отчет -->
      <div class="card white report" v-if="!isOpenNewPage">
        <div class="report__header index-color">
          <div class="report__header-title">
            <p class="title-mini white-text">Отчеты</p>
          </div>

          <div class="report__header-cross" @click="$emit('close')">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>

        <div
          class="report__body"
          v-if="allReportsTr !== null && allReportsTr.length"
        >
          <div class="report__btn-create right">
            <a
              class="waves-effect waves-light btn btn-large dark-green-color"
              @click="createNewReport"
              ><i class="material-icons left">add</i> Создать новый отчет</a
            >
          </div>
          <table-report />
        </div>
        <!-- Блок когда нет отчетов -->
        <empty-report
          v-if="allReportsTr !== null && allReportsTr.length === 0"
          @createNewReport="createNewReport"
        />
      </div>
      <!-- Блок создания отчетов -->
      <div class="card white report" v-else>
        <div class="report__header index-color">
          <div class="report__header-title">
            <p class="title-mini white-text">Создание отчета</p>
          </div>

          <div class="report__header-cross" @click="$emit('close')">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>
        <create-new-report @closeCreate="createNewReport" />
      </div>
    </div>
  </div>
</template>

<script>
import CreateNewReport from "./CreateNewReport";
import EmptyReport from "./EmptyReport";
import TableReport from "./TableReport/TableReport";
import { actionTypesTracker } from "@/store/modules/trackers";
import { mapState } from "vuex";
// import M from "materialize-css";
export default {
  name: "AppReport",
  components: { TableReport, EmptyReport, CreateNewReport },
  data() {
    return {
      modalInstance: null,
      isOpenNewPage: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.trackers.isLoading,
      allReportsTr: (state) => state.trackers.allReportsTr,
    }),
  },
  methods: {
    createNewReport() {
      this.isOpenNewPage = !this.isOpenNewPage;
    },
  },
  mounted() {
    this.$store.dispatch(actionTypesTracker.getAllReportsTrackers);
  },
};
</script>

<style></style>

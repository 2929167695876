<template>
  <l-popup ref="features">
    <div class="map-info__wrap">
      <div class="map-info__header">
        <div class="map-info__block-title">
<!--          <ul :id="'dropdownpop' + index" class="dropdown-content dropdown-content-update-map" :data-target="'dropdownpop' + this.index">-->
<!--            <li-->
<!--                class="modal-trigger"-->
<!--                data-target="modalTrackerProperty"-->
<!--                style="display: flex; align-items: center"-->
<!--                @click="getCommandTracker"-->
<!--            >-->
<!--              <span class="size-text-14 grey-text text-darken-4">Настройки трекера</span>-->
<!--            </li>-->
<!--&lt;!&ndash;            <li>&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="size-text-14 grey-text text-darken-4">Управление</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--            <hr />-->
<!--            <li-->
<!--                class="modal-trigger"-->
<!--                data-target="modalDelete"-->
<!--                style="display: flex; align-items: center"-->
<!--            >-->
<!--              <span class="size-text-14 grey-text text-darken-4">Удаление</span>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <i-->
<!--              class="material-icons dropdown-trigger tooltipped"-->
<!--              style="color: black; cursor: pointer"-->
<!--              :id="'dropdownpop' + index"-->
<!--              :data-target="'dropdownpop' + index"-->
<!--              role="button"-->
<!--              aria-label="Menu"-->
<!--              data-position="bottom"-->
<!--              data-tooltip="Меню устройства"-->
<!--              @click="handleClick"-->
<!--          >more_vert</i>-->
          <div class="map-info__img">
            <img
                src="../../assets/svg/car.svg"
                alt="car"
                class="shema-block__img-svg"
            />
          </div>
          <span
              class="map-info__text-title tooltipped"
              data-position="bottom"
              data-tooltip="IMEI трекера"
          >
            {{ item_tracker.serial }}
          </span>
        </div>
        <div
            class="tooltipped"
            data-target="modalChange"
            data-position="bottom"
            data-tooltip="Скорость"
        >
          <p class="map-info__text-description red-text">
            {{ item_tracker.speed }} км/ч
          </p>
        </div>
      </div>
      <p class="map-info__text">
        Широта/долгота:
        <span v-for="(coordinate, index) in item_tracker.coordinate" :key="index">
          {{ coordinate }}
        </span>
      </p>
      <p class="map-info__text">
        Адрес:
        <span
            class="tooltipped"
            data-position="bottom"
            enterDelay="600"
            exitDelay="200"
            :data-tooltip="place"
        >
          {{ place | truncate(70, "...") }}
        </span>
      </p>
      <p class="map-info__text">
        Активность:
        <span
            class="tooltipped"
            data-position="bottom"
            enterDelay="600"
            exitDelay="200"
            data-tooltip="Время получения последнего сообщения"
        >
          {{ item_tracker.datePositioning }}
        </span>
      </p>
    </div>
    <a
        class="btn-floating halfway-fab waves-effect waves-light mint-green-color"
        @click="openInfoTimer"
    >
      <i class="material-icons"> expand_more</i>
    </a>
    <!-- rss_feed -->
    <template v-if="!isOpenInfo">
      <hr />
      <div class="card-info-close brown lighten-5">
        <div
            class="card-info-close__prop tooltipped"
            data-position="bottom"
            data-tooltip="Сигнал"
        >
          <img src="../../assets/svg/signal.svg" alt="signal" />
          <span>{{ item_tracker.gsmSignal }} </span>
        </div>
        <div
            class="card-info-close__prop tooltipped"
            data-position="bottom"
            data-tooltip="Заряд батареи"
        >
          <!-- <img src="../../assets/img/antenna.svg" alt="battery" /> -->
          <battery :level="item_tracker.batteryVoltage" />
          <span>{{ item_tracker.batteryVoltage }}</span>
        </div>
        <div
            class="card-info-close__prop tooltipped"
            data-position="bottom"
            data-tooltip="Баланс"
        >
          <img src="../../assets/svg/ruble.svg" alt="balance" />
          <span>{{ item_tracker.simcardBalance }}RUB</span>
        </div>
        <div
            class="card-info-close__prop tooltipped"
            data-position="bottom"
            data-tooltip="Температура устройства"
        >
          <svg
              class=""
              enable-background="new 0 0 24 24"
              height="24"
              viewBox="0 0 24 24"
              width="24"
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <path
                  fill="red"
                  d="M11,12V6c0-1.66-1.34-3-3-3S5,4.34,5,6v6c-1.21,0.91-2,2.37-2,4c0,1.12,0.38,2.14,1,2.97V19h0.02c0.91,1.21,2.35,2,3.98,2 s3.06-0.79,3.98-2H12v-0.03c0.62-0.83,1-1.85,1-2.97C13,14.37,12.21,12.91,11,12z M5,16c0-0.94,0.45-1.84,1.2-2.4L7,13V6 c0-0.55,0.45-1,1-1s1,0.45,1,1v7l0.8,0.6c0.75,0.57,1.2,1.46,1.2,2.4H5z M18.62,4h-1.61l-3.38,9h1.56L16,10.7h3.63l0.8,2.3H22 L18.62,4z M16.47,9.39l1.31-3.72h0.08l1.31,3.72H16.47z"
              />
            </g>
          </svg>
          <span>{{ item_tracker.deviceTemperature }}°C</span>
        </div>
      </div>
    </template>

    <map-popup-tracker-info v-if="isOpenInfo" :item_tracker="item_tracker" />

    <a v-if="isOpenInfo" class="btn-floating halfway-fab waves-effect waves-light mint-green-color" @click="openInfoTimer">
      <i class="material-icons">expand_less</i>
    </a>
  </l-popup>
</template>

<script>
  import M from "materialize-css";
  import { LPopup } from "vue2-leaflet";
  import Battery from "../Battery.vue";
  import MapPopupTrackerInfo from "./MapPopupTrackerInfo.vue";
  import { actionTypesTracker } from '@/store/modules/trackers'

  export default {
    name: "AppMapPopupTracker",
    props: {
      item_tracker: {
        type: Object,
        default() {
          return {};
        },
      },
      index: {
        type: Number,
        default() {
          return {};
        },
      },
      place: {
        type: String,
        default() {
          return {};
        },
      },
    },
    components: {
      LPopup,
      Battery,
      MapPopupTrackerInfo,
    },
    data() {
      return {
        modalPopup: null,
        modalTooltip: null,
        isOpenInfo: false,
      };
    },
    methods: {
      openInfoTimer() {
        this.isOpenInfo = !this.isOpenInfo;
      },
      handleClick() {
        if (this.modalPopup) {
          this.modalPopup.open();
        }
      },
      getCommandTracker() {
        this.$store
            .dispatch(actionTypesTracker.getAllCommands, this.item_tracker.serial)
            .then(() => {})
            .catch((error) => {
              console.log("Ошибка", error);
            });
      },
    },
    filters: {
      truncate: function (text, length, clamp) {
        clamp = clamp || "...";
        var node = document.createElement("div");
        node.innerHTML = text;
        var content = node.textContent;
        return content.length > length ? content.slice(0, length) + clamp : content;
      },
    },
    mounted() {

      const elems = document.getElementById("dropdownpop" + this.index);
      if (elems) {
        this.modalPopup = M.Dropdown.init(elems, {
          // coverTrigger: false, // чтобы выпадающее меню не покрывало иконку
        });
      }

      var elemsTool = document.querySelectorAll(".tooltipped");
      this.modalTooltip = M.Tooltip.init(elemsTool);
    },
    beforeDestroy() {
      if (this.modalPopup) {
        this.modalPopup.destroy();
      }
      if (this.modalTooltip) {
        this.modalTooltip.forEach((tooltip) => tooltip.destroy());
      }
    },
  };
</script>

<style scoped>
.dropdown-content li {
  padding: 5px 8px 5px 8px !important;
}
</style>

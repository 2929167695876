<template>
  <div class="shema-block margin-sb" v-bind:class="chekBlock">
    <div class="shema-block__title">
<!--      shema-block__title-wrap-->
<!--      <div class="" @click="openDrop()">-->
<!--        <label class="shema-block__checkbox-wrap" @click="zoomToMarker()">-->
<!--          <input-->
<!--            type="checkbox"-->
<!--            class="filled-in black-text"-->
<!--            :value="item_tracker"-->
<!--            @change="onChange()"-->
<!--            v-model="checkedTracker"-->
<!--          />-->

<!--          <span class=""></span>-->
<!--        </label>-->
<!--        <div class="item-block__info">-->
<!--          <div class="item-block__info-name">-->
<!--            <blockquote>-->
              <div class="item-block-new">
                <div class="item-block-new__info">
                  <div class="item-block-new__info-wrap">
                    <div class="item-block-new__title index-color-text">{{ this.item_tracker.name }}</div>
                    <div class="">
                      <label class="shema-block__checkbox-wrap" @click="zoomToMarker()">
                        <input
                            type="checkbox"
                            class="filled-in black-text"
                            :value="item_tracker"
                            @change="onChange()"
                            v-model="checkedTracker"
                            :disabled="isActive"
                        />

                        <span class="">Показать на карте</span>
                      </label>
                    </div>
                  </div>

                  <div class="item-block-new__car">
                    <img
                        src="../../assets/svg/car.svg"
                        alt="car"
                        class="item-block-new__car-img"
                    />
                  </div>
                </div>
                <div class="item-block-new__icon">
                  <div
                      class="item-block__info-text tooltipped"
                      data-position="bottom"
                      :data-tooltip="'Процент заряда'"
                  >
                    <battery :level="batteryNow" />
                    <p class="index-color-text size-text-12">
                      {{ this.item_tracker.batteryValue}}%
                    </p>
                  </div>
                  <div
                      class="item-block__info-text tooltipped"
                      data-position="bottom"
                      :data-tooltip="'Напряжение'"
                  >
                    <img class="item-block__img" src="../../assets/svg/battery-charge.svg" alt="battery-charge">
                    <p class="index-color-text size-text-12">
                      {{ this.item_tracker.batteryVoltage }} В
                    </p>
                  </div>
                  <div
                      class="item-block__info-text tooltipped"
                      data-position="bottom"
                      :data-tooltip="'Баланс'"
                  >
                    <img class="item-block__img" src="../../assets/svg/ruble.svg" alt="battery-charge">
                    <p class="index-color-text size-text-12">
                      {{ this.item_tracker.simcardBalance }}
                    </p>
                  </div>
                  <div
                      class="item-block__info-text tooltipped"
                      data-position="bottom"
                      :data-tooltip="'Скорость'"
                  >
                    <img
                        src="../../assets/svg/speed-meter.svg"
                        alt=""
                        class="item-block__img"
                    />
                    <p class="index-color-text size-text-12">
                      {{ this.item_tracker.speed }} км/ч
                    </p>
                  </div>
                </div>
              </div>
<!--            </blockquote>-->
<!--            <blockquote>-->
<!--              <div class="item-block__title">-->
<!--                <div class="shema-block__img">-->
<!--                  <img-->
<!--                    src="../../assets/svg/car.svg"-->
<!--                    alt="car"-->
<!--                    class="shema-block__img-svg"-->
<!--                  />-->
<!--                </div>-->
<!--                <h6 class="index-color-text">{{ this.item_tracker.name }}</h6>-->
<!--              </div>-->
<!--              <div class="item-block__info-options" v-if="!isOpenDropdown">-->
<!--                <div-->
<!--                  class="item-block__info-text tooltipped"-->
<!--                  data-position="bottom"-->
<!--                  :data-tooltip="'Процент заряда'"-->
<!--                >-->
<!--                  <battery :level="batteryNow" />-->
<!--                  <p class="index-color-text size-text-12">-->
<!--                    {{ batteryNow }}%-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div-->
<!--                    class="item-block__info-text tooltipped"-->
<!--                    data-position="bottom"-->
<!--                    :data-tooltip="'Напряжение'"-->
<!--                >-->
<!--                  <img class="item-block__img" src="../../assets/svg/battery-charge.svg" alt="battery-charge">-->
<!--                  <p class="index-color-text size-text-12">-->
<!--                    {{ this.item_tracker.batteryVoltage }} В-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div-->
<!--                  class="item-block__info-text tooltipped"-->
<!--                  data-position="bottom"-->
<!--                  :data-tooltip="'Баланс'"-->
<!--                >-->
<!--                  <img class="item-block__img" src="../../assets/svg/ruble.svg" alt="battery-charge">-->
<!--                  <p class="index-color-text size-text-12">-->
<!--                    {{ this.item_tracker.simcardBalance }}-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div-->
<!--                  class="item-block__info-text tooltipped"-->
<!--                  data-position="bottom"-->
<!--                  :data-tooltip="'Скорость'"-->
<!--                >-->
<!--                  <img-->
<!--                    src="../../assets/svg/speed-meter.svg"-->
<!--                    alt=""-->
<!--                    class="item-block__img"-->
<!--                  />-->
<!--                  <p class="index-color-text size-text-12">-->
<!--                    {{ this.item_tracker.speed }} км/ч-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </blockquote>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <ul
        :id="'dropdown' + this.index"
        class="dropdown-content dropdown-content-update"
      >

        <li
          style="display: flex; align-items: center"
          @click="openModal(), getCommandTracker()"
        >
          <span class="size-text-14 grey-text text-darken-4"
            >Настройки трекера</span
          >
        </li>
        <hr />
        <li
            style="display: flex; align-items: center"
            @click="openModalControl()"
        >
          <span class="size-text-14 grey-text text-darken-4"
          >Управление</span
          >
        </li>
        <hr />
        <!--          class="modal-trigger"-->
        <!--          data-target="modalDelete"-->
        <li
          @click="openModalDeleteTracker()"
          style="display: flex; align-items: center"
        >
          <span class="size-text-14 grey-text text-darken-4">Удалить</span>
        </li>
      </ul>
      <div class="item-block-new__btn">
      <button class="btn waves-effect waves-light dark-green-color" type="submit" name="action" style="width: 100%;"  :id="'drop' + this.index"  :data-target="'dropdown' + this.index"
              @click="handleClick()">Настройки устройства
        <i class="material-icons right">settings</i>
      </button>
      </div>
<!--      <i-->
<!--        class="material-icons dropdown-trigger index-color-text"-->
<!--        style="cursor: pointer;"-->
<!--        :id="'drop' + this.index"-->
<!--        :data-target="'dropdown' + this.index"-->
<!--        @click="handleClick()"-->
<!--        role="button"-->
<!--        aria-label="Menu"-->
<!--        >more_vert</i-->
<!--      >-->

    </div>
    <!-- <Transition name="fade" mode="out-in" :duration="550"> -->
    <div class="dropdown__menu shema-block__info" v-if="isOpenDropdown">
      <div class="">
<!--        <battery :level="this.item_tracker.batteryVoltage" />-->
        <p class="info-text">
          Процент заряда:
          <span class="add-text-success w500">
            {{ batteryNow }} %
          </span>
        </p>
      </div>
      <div class="">
        <p class="info-text">
          Напряжение:
          <span class="index-color-text w500">
            {{ this.item_tracker.batteryVoltage }} B
          </span>
        </p>
      </div>
      <div class="">
        <p class="info-text">
          Баланс:
          <span class="index-color-text w500">
            {{ this.item_tracker.simcardBalance }} RUB
          </span>
        </p>
      </div>
      <div class="">
        <p class="info-text">
          Скорость:
          <span class="index-color-text w500"
            >{{ this.item_tracker.speed }} км/ч</span
          >
        </p>
      </div>
      <div class="item-block__icon-block">
        <!-- Отпределение локации устройства -->
        <img
          src="../../assets/svg/location.svg"
          class="item-block__icon"
          @click="zoomToMarker()"
        />
      </div>
    </div>
    <!-- </Transition> -->
  </div>
</template>

<script>
import M from "materialize-css";
import Battery from "../Battery.vue";
import { actionTypesTracker } from '@/store/modules/trackers'
import { actionTypesModal } from '@/store/modules/all-modal'
export default {
  components: { Battery },
  name: "AppItemTracker",
  props: {
    item_tracker: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return {};
      },
    },
    value: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
    isActive: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      checkedP: false,
      modalInstance: null,
      modalTooltip: null,
      isOpenDropdown: false,
    };
  },

  computed: {
    checkedTracker: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedP = val;
      },
    },
    chekBlock: function () {
      return {
        "active-track": this.isOpenDropdown,
      };
    },
    batteryNow() {
      console.log("asgdsaaaadgaga" + this.item_tracker.batteryValue);
      return this.item_tracker.batteryValue
    }

  },
  methods: {

    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateChechTracker");
    },
    openModal() {
      this.$store.dispatch(actionTypesModal.openModalTracker)
    },
    openModalControl() {
      this.$store.dispatch(actionTypesModal.openModalTrackerControl)
    },
    openModalDeleteTracker() {
      this.$store.dispatch(actionTypesModal.openModalDeleteTracker)
    },
    zoomToMarker() {
      console.log(this.item_tracker, "Click marker");
      this.$emit("zoomToMarker", this.item_tracker);
    },
    handleClick(key) {
      const elems = document.getElementById("drop" + this.index);
      this.modalInstance = M.Dropdown.init(elems);
      this.modalInstance.open(key);
      // Записываем Item
      this.$store
          .dispatch(actionTypesTracker.safeItemTracker, this.item_tracker)
          .then(() => {
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
    getCommandTracker() {
      //Получение журнала команд определенного трекера
      this.$store
          .dispatch(actionTypesTracker.getAllCommands, this.item_tracker.serial)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
    openDrop() {
      this.isOpenDropdown = !this.isOpenDropdown;
    },
  },
  mounted() {
    const elems = document.getElementById("drop" + this.index);
    this.modalInstance = M.Dropdown.init(elems);

    var elemsTool = document.querySelectorAll(".tooltipped");
    this.modalTooltip = M.Tooltip.init(elemsTool);

    // Инициализация нового модального окна
    const modalControlElem = document.querySelector("#modalControlTracker");
    if (modalControlElem) {
      this.modalInstance = M.Modal.init(modalControlElem);
    } else {
      console.error("Модальное окно с ID #modalControlTracker не найдено.");
    }
  },
};
</script>

<style scoped>
fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

[type="checkbox"].filled-in:checked:disabled + span:not(.lever):after {

  background-color: #b4b4b4 !important;
  border-color: #b4b4b4 !important;
  border-right: 2px solid #b4b4b4 !important;
  border-bottom: 2px solid #b4b4b4 !important;
}

[type="checkbox"].filled-in:checked + span:not(.lever):before {

  border-right: 2px solid #ffffff !important;
  border-bottom: 2px solid #ffffff !important;
  /* if just wanted to change the color of tick mark you can change the above borders 	 */

}

[type="checkbox"].filled-in:checked + span:not(.lever):after {

  border: 2px solid #6378A6 !important;
  background-color: #6378A6 !important;

  /* if you want to change the filling color when the user checks the box by mouse click you can change the above values */
}

[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {

  background-color: #414141 !important;
  border-color: #414141 !important;
}


</style>

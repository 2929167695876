import authAPi from "@/api/auth.js";
import { setItem } from "@/helpers/persistanceStorage";
import axios from "axios";
import router from "@/router";

const state = {
  isSubmitting: false,
  isLoading: false, //ожидание запроса JSON.parse(localStorage.getItem('catalogs') || '[]')
  token: localStorage.getItem("accessTokenStore") || "",
  userInfo: JSON.parse(localStorage.getItem("userInfo") || "[]"),
  isAuthenticated: false, //авторизирован ли пользователь
  validationErrors: null, //ошибки
};

export const mutationTypes = {
  loginStart: "[auth] loginStart",
  loginSuccess: "[auth] loginSuccess",
  loginFailure: "[auth] loginFailure",

  exitLoginSuccess: "[auth] exit success",
};
export const actionTypes = {
  login: "[auth] login",
  exitLogin: "[auth] exit",
};

const mutations = {
  [mutationTypes.loginStart](state) {
    state.isSubmitting = true;
    state.isAuthenticated = false;
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.loginSuccess](state, data) {
    state.isSubmitting = false;
    state.isAuthenticated = true;
    state.token = data.token;
    state.userInfo = data.user;
    state.isLoading = false;
    state.isLoggedIn = true;
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isSubmitting = false;
    state.isAuthenticated = false;
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.exitLoginSuccess](state) {
    state.isSubmitting = false;
    state.token = "";
    state.isAuthenticated = true;
  },
};
const actions = {
  [actionTypes.exitLogin](context) {
    context.commit(mutationTypes.exitLoginSuccess);
    localStorage.removeItem("accessTokenStore");
    localStorage.removeItem("userInfo");
    delete axios.defaults.headers.common["Authorization"];
    router.push({ name: "Login" });
  },
  [actionTypes.login](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.loginStart);
      authAPi
        .login(credentials)
        .then((response) => {
          setItem("accessTokenStore", response.data.token);
          setItem("userInfo", response.data.user);
          const data = response.data;
          context.commit(mutationTypes.loginSuccess, data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.loginFailure, {message: 'Неправильный логин или пароль'});
          localStorage.removeItem("accessTokenStore");
          console.log("ошибка", response);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="container-login-new">
    <div class="wrap-loader" v-if="isLoading">
      <div id="loader"></div>
    </div>
    <div class="login__form">
      <div class="logo__mobile">
        <img class="logo__mobile-svg" src="../assets/glonas/icon.png" alt="logo" />
        <div class="info-block__title">
          <p class="info-block__title-text">ГлонассДВ</p>
        </div>
      </div>
      <div class="form-login">
        <div class="form-login__title">
          <p class="form-login__title-text">Вход</p>
        </div>
        <div class="error-messages">
          <AppValidationErrors
            v-if="validationErrors"
            :validation-errors="validationErrors"
          />
        </div>
        <form @submit.prevent="onSubmit" class="form-login__form">
          <fieldset class="form-login__input-wrap">
            <p class="login-text" for="email">Логин</p>
            <input
              type="text"
              class="login-input"
              placeholder="Логин"
              name="email"
              v-model="email"
            />
          </fieldset>
          <fieldset class="form-login__input-wrap">
            <p class="login-text" for="password">Пароль</p>
            <input
              type="password"
              class="login-input"
              name="password"
              placeholder="Пароль"
              v-model="password"
            />
          </fieldset>
          <div class="form-login__submit ">
            <input
              type="button"
              value="Войти"
              class="login-btn index-color"
              @click="onSubmit()"
              :disabled="!this.email || !this.password"
            />
          </div>
        </form>
      </div>
    </div>
    <div class="login__info">
      <div class="info-block">
        <div class="info-block__title">
          <p class="info-block__title-text">ГлонассДВ</p>
<!--          <img-->
<!--            class="logo__mobile-svg"-->
<!--            src="../assets/svg/Logo-white.svg"-->
<!--            alt="logo"-->
<!--          />-->

          <div class="info-block__subscribe">
            <p class="info-block__subscribe-text">
              Производитель устройств ЭРА-ГЛОНАСС
            </p>
          </div>
        </div>
         <div class="info-block__logo">
           <img src="../assets/glonas/icon.png" alt="Glonass">
        </div>
        <div class="info-block__footer">
        <div class="info-block__description">
<!--          <div class="info-block__description-img">-->
<!--            <img src="../assets/svg/dot.svg" alt="dot">-->
<!--          </div>-->
          <p class="info-block__description-text">
            Система спутникового мониторинга
          </p>
        </div>
          <div class="info-block__description">
<!--            <div class="info-block__description-img">-->
<!--              <img src="../assets/svg/dot.svg" alt="dot">-->
<!--            </div>-->
<!--            <p class="info-block__description-text">-->
<!--              Система автоматизированного освещения-->
<!--            </p>-->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppValidationErrors from "@/components/ValidationErrors.vue";
import { actionTypes } from "@/store/modules/auth";
import { mapState } from "vuex";

export default {
  name: "AppLogin",
  data() {
    return {
      email: "",
      password: "",
      title: "Авторизация",
    };
  },
  components: {
    AppValidationErrors,
  },
  computed: {
    ...mapState({
      validationErrors: (state) => state.auth.validationErrors,
      isLoading: (state) => state.auth.isLoading,
    }),
  },
  methods: {
    onSubmit() {
      // this.$router.push({ name: "Index" });
      this.$store
        .dispatch(actionTypes.login, {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          if (!this.validationErrors) {
            this.$router.push({ name: "Index" });
          }
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  beforeMount() {
    document.title = this.title;
  },
  beforeUpdate() {
    document.title = this.title;
  },
};
</script>

<template>
  <div>
    <div id="modalAboutProgram" class="modal">
      <div class="modal-content">
        <div class="modal-info__title-block-program mint-green-color">
          <p class="modal-info__title-program">О программе</p>
          <div class="modal-info__close-program modal-close">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>
        <div class="modal-body-program">
          <div class="modal-body-program__logo">
            <img
              src="../assets/glonas/icon.png"
              alt="logo"
              class="modal-body-program__logo-img"
            />
          </div>
          <div class="modal-body-program__title">
            <p class="modal-body-program__title-text">
              ООО «ГлонассДВ»
              Система спутникового мониторинга

            </p>
            <p class="modal-body-program__title-text">
              Версия: 1.0.0 от 31.08.2024
            </p>
          </div>
        </div>
        <div class="modal-body-contact">
          <div class="modal-body-contact__info">
            <p class="modal-body-contact__info-text">
              Разработанно ООО "AutoSystem" , 2024
            </p>
            <p class="modal-body-contact__info-text">Все права защищены</p>
            <p class="modal-body-contact__info-text">
              Почта:
              <a href="mailto:mail@ya.ru&body=Вопрос?subject=Вопрос"
                >alexandr.kashtura.dev@gmail.com</a
              >
            </p>
<!--            <p class="modal-body-contact__info-text">-->
<!--              Телефон: <a href="tel:+18475555555">89029929299</a>-->
<!--            </p>-->
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          name="btn_login"
          class="modal-close col s12 btn btn-large waves-effect dark-green-color"
        >
          Закрыть
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
export default {
  name: "AppModalTimerHistory",
  data() {
    return {};
  },

  methods: {
    handleClick() {
      this.modalInstance.close();
    },
  },
  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);
  },
};
</script>
<style scoped>
.modal {
  width: 30% !important;
  border-radius: 8px !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}
.message {
  font-size: 16px;
}
</style>

<template>
  <div>
    <!-- Modal Structure -->
    <div id="modal4" class="modal">
      <form class="modal__size" id="modalTimer" v-if="this.item_light !== null">
        <div class="modal-content">
          <div class="modal-info__title-block index-color">
            <p class="modal-info__title">
              Настройки:
              <span>{{ this.item_light.nameTimer }}</span>
            </p>
            <div class="modal-info__close modal-close">
              <i class="material-icons white-text"> close</i>
            </div>
          </div>
          <div class="modal-scroll">
            <div class="modal-timer__wrap">
              <p class="modal-title">Время работы таймера:</p>

              <div class="modal-timer__block-time">
                <div class="modal-timer__wrap-input">
                  <p>Включение</p>
                  <div class="modal-timer__wrap-input-svg">
                    <svg viewBox="0 0 64 64" class="modal-timer__svg">
                      <g data-name="katman 2">
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M23 51v-4.56M43.87 40.83A7.26 7.26 0 0041 46.44"
                        />
                        <path
                          fill="#ecf0f1"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M35 54.46V57a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2.54a4 4 0 0 0 2 .54h2a4 4 0 0 0 2-.54z"
                        />
                        <path
                          fill="#ecf0f1"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M37 47v4a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4v-4z"
                        />
                        <path
                          fill="#f1c40f"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M47 25.82a15 15 0 0 1-5.63 11.89A11.24 11.24 0 0 0 37 46.44V47H27v-.56a10.65 10.65 0 0 0-4.08-8.5A15 15 0 1 1 47 25.82z"
                        />
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M30 47V35M34 47V35M30 35l-6-6M34 35l6-6M26 15.61A11.94 11.94 0 0132 14M20 26a12 12 0 012.57-7.42M32 8V4M19.27 13.27l-2.83-2.83M14 26h-4M50 26h4M44.73 13.27l2.83-2.83M27 50h10"
                        />
                        <path fill="none" d="M0 0h64v64H0z" />
                      </g>
                    </svg>
                    <vue-timepicker
                      format="HH:mm:ss"
                      name="startTimeOn"
                      v-model="normalizedTimer.startTime"
                    ></vue-timepicker>
                  </div>
                </div>
                <div class="modal-timer__wrap-input">
                  <p>Выключение</p>
                  <div class="modal-timer__wrap-input-svg">
                    <svg viewBox="0 0 64 64" class="modal-timer__svg">
                      <g data-name="katman 2">
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M23 51v-4.56M43.87 40.83A7.26 7.26 0 0041 46.44M35 54.46V57a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2.54a4 4 0 002 .54h2a4 4 0 002-.54z"
                        />
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M37 47v4a4 4 0 01-4 4h-2a4 4 0 01-4-4v-4zM47 25.82a15 15 0 01-5.63 11.89A11.24 11.24 0 0037 46.44V47H27v-.56a10.65 10.65 0 00-4.08-8.5A15 15 0 1147 25.82zM30 47V35M34 47V35M30 35l-6-6M34 35l6-6M26 15.61A11.94 11.94 0 0132 14M20 26a12 12 0 012.57-7.42M32"
                        />
                        <path fill="none" d="M0 0h64v64H0z" />
                      </g>
                    </svg>
                    <vue-timepicker
                      format="HH:mm:ss"
                      name="endTimeOff"
                      v-model="normalizedTimer.endTime"
                    ></vue-timepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-timer__wrap">
              <p class="modal-title">Ручное управление таймером:</p>
              <div class="switch__wrap">
                <div class="switch">
                  <label>
                    Off
                    <input
                      type="checkbox"
                      :disabled="offDisable"
                      :checked="normalizedTimer.status"
                      @change="changeStatusTimer"
                    />
                    <span class="lever"></span>
                    On
                  </label>
                </div>
                <div
                  v-if="offDisable"
                  class="modal-title__error switch__block red-text text-darken-1"
                >
                  <p>Ожидание ответа таймера...</p>
                </div>
              </div>
            </div>
            <div class="row modal-timer__wrap">
              <div class="modal-add-block__title-with-btn">
                <p class="modal-title">Координаты:</p>
                <div
                  class="modal-timer__title-with-btn"
                  v-if="!isCopyCoordinate"
                >
                  <button
                    class="btn waves-effect waves-light mint-green-color"
                    name="action"
                    @click="pasteCoordinate"
                    type="button"
                  >
                    Вставить из буфера
                    <i class="material-icons right">send</i>
                  </button>
                </div>
                <div class="modal-timer__title-with-btn" v-else>
                  <button
                    class="btn waves-effect waves-light mint-green-color"
                    name="action"
                    @click="pasteCoordinate"
                    type="button"
                  >
                    Вернуть
                    <i class="material-icons right">send</i>
                  </button>
                </div>
              </div>
              <p
                v-if="isCopyCoordinate && this.copyCoordinate === null"
                class="modal-title__error red-text text-darken-1"
              >
                Координаты не скопированны*
              </p>
              <p
                v-if="isCopyCoordinate && this.copyCoordinate !== null"
                class="modal-title__error green-text text-darken-1"
              >
                Координаты успешно скопированны!
              </p>
              <div
                class=""
                v-if="
                  !isCopyCoordinate ||
                  (isCopyCoordinate && this.copyCoordinate === null)
                "
              >
                <div class="input-field col s6">
                  <p>Широта</p>
                  <input
                    id="icon_lat"
                    type="text"
                    name="latitude"
                    class="validate"
                    v-model="normalizedTimer.coordinate[0]"
                  />
                </div>
                <div class="input-field col s6">
                  <p>Долгота</p>

                  <input
                    id="icon_lon"
                    type="text"
                    name="longitude"
                    class="validate"
                    v-model="normalizedTimer.coordinate[1]"
                  />
                </div>
              </div>
              <div
                class=""
                v-if="isCopyCoordinate && this.copyCoordinate !== null"
              >
                <div class="input-field col s6">
                  <p>Широта</p>
                  <input
                    id="icon_lat"
                    type="text"
                    name="latitude"
                    class="validate"
                    v-bind:value="copyCoordinate[0].lat"
                  />
                </div>
                <div class="input-field col s6">
                  <p>Долгота</p>

                  <input
                    id="icon_lon"
                    type="text"
                    name="longitude"
                    class="validate"
                    v-bind:value="copyCoordinate[0].lng"
                  />
                </div>
              </div>
            </div>
            <div class="row modal-timer__wrap">
              <div class="input-field">
                <i class="material-icons prefix">phone</i>
                <input
                  id="icon_telephone"
                  type="tel"
                  name="phone"
                  class="validate"
                  v-model="normalizedTimer.phone"
                />
              </div>
            </div>

            <div class="timer-btn">
              <button
                type="button"
                @click="onSubmit()"
                name="btn_login"
                class="modal-close col s12 btn btn-large waves-effect mint-green-color"
              >
                Задать настройки
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import VueTimepicker from "vue2-timepicker";

import { Settings } from "luxon";
import { actionTypes } from "@/store/modules/lights";
import { mapState } from "vuex";

Settings.defaultLocale = "rus";

export default {
  name: "AppModalTimer",
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      modalInstance: null,
      startDateTime: "",
      endDateTime: "",
      statusNow: null,
      newDataTimer: [],
      isCopyCoordinate: false,
      isDisabledSwitch: false,
      timeoutID: null,
    };
  },

  components: {
    VueTimepicker,
  },

  computed: {
    ...mapState({
      copyCoordinate: (state) => state.lights.copyCoordinate,
      messageStatus: (state) => state.socket.message,
    }),
    normalizedTimer() {
      return this.item_light;
    },

    getNewStatus: function () {
      if (
        this.messageStatus &&
        this.messageStatus.id === this.normalizedTimer.id &&
        this.messageStatus.status !== this.statusNow
      ) {
        console.log("Пришло сообщение от этого таймера и статус поменятся");
        return true;
      } else if (!this.messageStatus) {
        return false;
      } else {
        return false;
      }
    },
    //ЕСЛИ  true то записись об ожидании ответа сервера и disable
    offDisable: function () {
      if (this.isDisabledSwitch === false) {
        return false;
      } else if (this.isDisabledSwitch && this.getNewStatus) {
        console.log("Таймер идет, но статус поменялся");
        this.cancelTimer();
        return false;
      } else return true;
    },
  },
  methods: {
    onSubmit() {
      const forms = document.forms;
      const formData = new FormData(forms[2]);
      this.newDataTimer = [];
      for (let [name, value] of formData) {
        this.newDataTimer = { ...this.newDataTimer, [name]: value };
      }
      this.newDataTimer = { ...this.newDataTimer, imei: this.item_light.imei };

      this.$store
        .dispatch(actionTypes.settingsTimer, this.newDataTimer)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    pasteCoordinate() {
      this.isCopyCoordinate = !this.isCopyCoordinate;
    },
    //Удаление таймера
    cancelTimer() {
      console.log("Удаление таймера");
      clearTimeout(this.timeoutID);
    },
    changeStatusTimer() {
      this.cancelTimer();
      this.statusNow = this.normalizedTimer.status;
      console.log(this.statusNow, "СТАТУС СЕЙЧАС ОТСЧЕТ ПОШЕЛ");
      this.isDisabledSwitch = true;
      if (this.normalizedTimer.status === true) {
        console.log("Выключить");
        this.$store
          .dispatch(actionTypes.postTimerOff, {
            imei: this.item_light.imei,
          })
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else {
        console.log("Включить");
        this.$store
          .dispatch(actionTypes.postTimerOn, {
            imei: this.item_light.imei,
          })
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
      this.timeoutID = setTimeout(() => {
        this.isDisabledSwitch = false;

        console.log("прошло 60 секунд");
      }, 65000);
    },
  },
  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);
    M.updateTextFields();
  },
};
</script>
<style scoped>
.modal {
  width: 35% !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.timer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

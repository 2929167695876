<template v-if="isRouteVisible">
  <l-layer-group layer-type="overlay" :name="'Layer' + item_route.id">
    <l-layer-group>
      <l-marker
          v-for="marker in filteredMarkers"
          :key="marker.id"
          :lat-lng="marker.geolocation"
          @click="zoomInPolylinaMark(marker.geolocation)"
      >
        <l-icon
            :icon-anchor="staticAnchor"
            class-name="icon-custom-on-start"
            v-if="marker.iconMarker === 'START'"
        >
          <div
              class="icon-custom-on-start__icon"
              :style="{ border: `5px solid` + backgroundColor }"
          ></div>
        </l-icon>
        <l-icon
            :icon-anchor="staticAnchor"
            class-name="icon-custom-on-start"
            v-if="marker.iconMarker === 'END'"
        >
          <div
              :style="{ background: backgroundColor }"
              class="icon-custom-on-start__icon-finish"
          >
            <img
                class="icon-custom-on-start__icon-finish-svg"
                src="../../assets/svg/finish.svg"
                alt=""
            />
          </div>
        </l-icon>
      </l-marker>
    </l-layer-group>
    <l-polyline
        :lat-lngs="item_route.polyline"
        :color="backgroundColor"
        :weight="weight"
        ref="polyline"
    />
  </l-layer-group>
</template>


<script>
import {
  LMarker,
  LIcon,
  LPolyline,
  LLayerGroup,
} from "vue2-leaflet";
import L from 'leaflet';
import 'leaflet-polylinedecorator';

export default {
  name: "AppItemGroupPolyline",
  props: {
    item_route: {
      type: Object,
      required: true,
    },
    isRouteVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      weight: 5,
      staticAnchor: [16, 37],
      arrowDecorator: null,
    };
  },
  computed: {
    backgroundColor() {
      // Определяем цвета для каждой машины на основе её ID или индекса
      const routeColors = {
        1: '#000e4d', // красный
        2: '#595959', // синий
        // добавьте другие цвета при необходимости
      };
      // Используем цвет в зависимости от ID маршрута
      return routeColors[this.item_route.id] || '#000000'; // Если ID нет в списке, используется черный цвет
    },

    filteredMarkers() {
      return this.isRouteVisible ? this.item_route.markers : [];
    },
  },
  watch: {
    isRouteVisible: {
      immediate: true,
      handler(newValue) {
        console.log("Change " + newValue);
        this.updatePolylineVisibility(newValue);
        this.toggleMarkersVisibility(newValue);
        if (newValue) {
          this.$nextTick(() => {
            this.createArrowDecorator();
          });
        } else if (this.arrowDecorator) {
          this.arrowDecorator.remove();
          this.arrowDecorator = null;
        }
      },
    },
  },
  methods: {
    toggleMarkersVisibility(isVisible) {
      console.log("TTTTTTTTTTTTTT" + isVisible);
      this.$nextTick(() => {
        this.item_route.markers.forEach(marker => {
          const markerRef = this.$refs[`marker-${marker.id}`];
          if (markerRef && markerRef.mapObject) {
            markerRef.mapObject.setOpacity(isVisible ? 1 : 0);
          }
        });
      });
    },

    updatePolylineVisibility(isVisible) {
      console.log("FFFFFFFFFFFFFFFFFFFFFFFF" + isVisible);
      this.$nextTick(() => {
        const polyline = this.$refs.polyline ? this.$refs.polyline.mapObject : null;
        if (polyline) {
          polyline.setStyle({opacity: isVisible ? 1 : 0});
          console.log(`Polyline visibility set to ${isVisible ? 'visible' : 'hidden'}`);
        } else {
          console.log('Polyline not found or mapObject missing');
        }
      });
    },

    zoomInPolylinaMark(latLng) {
      this.$emit("zoomInPolylinaMark", latLng);
    },

    // getRandomColor() {

//   const letters = '0123456789ABCDEF';
//   let color = '#';
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   console.log ("Цвет:  " + color);
//   return color;
// },

    createArrowDecorator() {
      const polyline = this.$refs.polyline ? this.$refs.polyline.mapObject : null;
      if (polyline && !this.arrowDecorator) {
        console.log('Polyline exists, creating arrow decorator...');
        this.arrowDecorator = L.polylineDecorator(polyline, {
          patterns: [
            {
              offset: 50,
              repeat: 100,
              symbol: L.Symbol.arrowHead({
                pixelSize: 15,
                polygon: false,
                pathOptions: {stroke: true, color: this.backgroundColor, weight: this.weight},
              }),
            },
          ],
        });
        this.arrowDecorator.addTo(polyline._map);
      } else {
        console.log('Polyline not found or arrow decorator already exists.');
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      console.log('Component mounted');
      if (this.isRouteVisible) {
        this.toggleMarkersVisibility(true);
        this.updatePolylineVisibility(true);
      }
    });
  },

  components: {
    LMarker,
    LIcon,
    LPolyline,
    LLayerGroup,
  },
};
</script>



<style scoped>
.icon-custom-on-start__icon {
  background-color: red;
  border-radius: 50%;
}

.icon-custom-on-start__icon-finish {
  background-color: green;
}

.arrow-icon {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid red;
  display: inline-block;
}
</style>

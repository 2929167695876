<template>
  <div class="">
    <div class="modal-add-block__title">
      <p class="modal-title__error red-text text-darken-1">{{ message }}</p>
    </div>
    <div class="input-field col s12">
      <input id="model" name="name" type="text" v-model="nameTracker" />
      <label class="active" for="text">Задайте имя</label>
    </div>
  </div>
</template>

<script>
// actionTypesTracker
import { actionTypesTracker } from "@/store/modules/trackers";
export default {
  name: "AppCreateNewTracker",
  data() {
    return {
      nameTracker: "",
      message: "",
    };
  },
  methods: {
    submitData(info) {
      const newCreateTracker = {
        serial: info,
        name: this.nameTracker,
      };
      this.$store
        .dispatch(actionTypesTracker.addTracker, newCreateTracker)
        .then((response) => {
          if (response.isComplete) {
            this.nameTracker = "";
            this.$emit("successAddTracker");
            this.$store
              .dispatch(actionTypesTracker.getList)
              .then(() => {})
              .catch((er) => {
                console.log("Ошибка", er);
              });
          } else {
            this.message = response.message;
          }
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
};
</script>

<style></style>

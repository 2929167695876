import axios from "@/api/axios";

const getAllTracker = () => {
  return axios.get("/get/all/beacon");
};
const deleteTracker = (serial) => {
  return axios.post(`delete/beacon/${serial}`);
}
const getAllReportsTrackers = () => {
  return axios.get("/get/all/report");
};
const getLogsByDate = (info) => {
  return axios.post("/create/beacon/log", info);
};
const deleteReportTracker = (id) => {
  return axios.post(`delete/report/${id}`);
};
const downloadReport = (info) => {
  return axios.post("/download/report", info, {
    responseType: "blob",
  });
};
const downloadReportPdf  = (info) => {
  return axios.post("/download/report/pdf", info, {
    responseType: "blob",
  });
};
const addTracker = (info) => {
  return axios.post(`registration/beacon`, info);
};

const sendCommands = (arrayCommand) => {
  return axios.post('/send/commands', arrayCommand)
}

const getAllCommands = (serial) => {
  return axios.get(`/get/commands/beacon?serial=${serial}`, )
}

const changeNameTracker = (info) => {
  return axios.post(`/edit/name/beacon`, info )
}
const submitNewCommand = (info) => {
  return axios.post('/send/command/arbitrary',info)
}

export default {
  getAllTracker,
  deleteTracker,
  getAllReportsTrackers,
  getLogsByDate,
  deleteReportTracker,
  downloadReport,
  addTracker,
  sendCommands,
  getAllCommands,
  changeNameTracker,
  submitNewCommand,
  downloadReportPdf
};

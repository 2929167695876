<template>
  <main>
    <div style="height: 100vh; width: 100%; position: fixed">
      <l-map :zoom="zoom" :center="center" ref="myMap" :options="{ zoomControl: false }">
        <LTileLayer :url="url"></LTileLayer>

        <div v-for="item in checkedTimer" :key="item.id">
          <l-marker
              @click="openPopUp(item.coordinate)"
              :lat-lng="item.coordinate"
              :icon="
              item.temperature <= 50
                ? iconTimerGreen
                : item.temperature >= 70
                ? iconTimerRed
                : iconTimerYellow
            "
          >
            <MapPopup v-bind:item_light="item" v-bind:place="place" @itemData="itemData(item)" />
          </l-marker>
        </div>
        <div v-for="item in checkedDemir" :key="item.dimmerId">
          <l-marker @click="openPopUp(item.coordinate)" :lat-lng="item.coordinate" :icon="iconDemir">
            <MapPopupDemir v-bind:item_demir="item" v-bind:place="place" @itemData="itemData(item)" />
          </l-marker>
        </div>
        <div v-for="(item, index) in checkedTrackers" :key="item.id">
          <l-marker @click="openPopUp(item.coordinate)" :lat-lng="item.coordinate" :icon="iconTracker">
            <MapPopupTracker v-bind:item_tracker="item" :index="index" v-bind:place="place" />
          </l-marker>
        </div>

        <l-control-zoom class="leaflet-control-zoom" position="topright"></l-control-zoom>

        <template v-if="routes">
          <AppItemGroupPolyline
              v-for="item in routes"
              :key="item.id"
              v-bind:item_route="item"
              :isRouteVisible="isRouteVisible"
              @zoomInPolylinaMark="zoomInPolylinaMark"
          />
        </template>
      </l-map>
    </div>
    <button @click="toggleRouteVisibility">Toggle Route Visibility</button> <!-- Кнопка для переключения видимости маршрута -->
  </main>
</template>

<script>
import M from "materialize-css";

import {
  LMap,
  LTileLayer,
  LMarker,
  LControlZoom,
} from "vue2-leaflet";
import { mapState } from "vuex";
import { icon } from "leaflet";
import axios from "axios";
import MapPopupDemir from "./MapPopup/MapPopupDemir.vue";
import MapPopup from "./MapPopup/MapPopup.vue";
import MapPopupTracker from "./MapPopup/MapPopupTracker.vue";
import AppItemGroupPolyline from '@/components/Routing/ItemGroupPolyline.vue';
import {actionTypes} from "@/store/modules/auth";

export default {
  name: "Map",
  props: {
    isRouteVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppItemGroupPolyline,
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    MapPopupTracker,
    MapPopup,
    MapPopupDemir,
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 14,
      center: [54.982301, 82.893469],
      modalInstance: null,
      modalTooltip: null,
      searchCoord: {},
      place: "...",
      bounds: null,
      markers: [],
      openCart: true,
      iconTimerGreen: icon({
        iconUrl: require("../assets/img/timerGreen.svg"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconTimerYellow: icon({
        iconUrl: require("../assets/img/timerYellow.svg"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconTimerRed: icon({
        iconUrl: require("../assets/img/timerRed.svg"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconMeter: icon({
        iconUrl: require("../assets/img/meter.png"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconDemir: icon({
        iconUrl: require("../assets/img/street-light.png"),
        iconSize: [35, 35],
        iconAnchor: [16, 37],
      }),
      iconTracker: icon({
        iconUrl: require("../assets/svg/carBefore.svg"),
        iconSize: [45, 45],
        iconAnchor: [16, 37],
      }),
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.lights.isSubmitting,
      lights: (state) => state.lights.lights,
      checkedTimer: (state) => state.lights.checkedTimer,
      checkedDemir: (state) => state.demir.checkedDemir,
      checkedTrackers: (state) => state.trackers.checkedTrackers,
      routes: (state) => state.route.routes,
    }),
  },

  methods: {
    toggleRouteVisibility() {
      this.$emit('toggle-route-visibility'); // Эмитим событие наверх, чтобы изменить видимость маршрута
    },
    zoomInPolylinaMark(latLng) {
      const mapComponent = this.$refs.myMap;
      mapComponent.mapObject.flyTo(latLng, 15);
    },
    openPopUp(latLng) {
      this.searchCoord = {
        lat: latLng[0],
        lng: latLng[1],
      };
      const mapComponent = this.$refs.myMap;
      mapComponent.mapObject.flyTo(this.searchCoord, 15);
      axios
          .get(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.searchCoord.lat}&lon=${this.searchCoord.lng}&zoom=18&addressdetails=1`
          )
          .then((response) => {
            this.place = response.data.display_name;
          })
          .catch(() => {
            this.place = "Место не найдено";
          });
    },
    handleClickTimer() {
      this.$emit("itemDataHistory", this.item_data);
    },
    itemData(items) {
      this.$emit("itemIndex", items);
    },
    addMarker(event) {
      this.openCart = true;
      this.markers = [];
      this.markers.push(event.latlng);
    },
    closeCart() {
      this.openCart = false;
    },
    copy() {
      this.openCart = false;
      this.$store
          .dispatch(actionTypes.copyCoordinate, this.markers)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
  },
  mounted() {
    var elemsTool = document.querySelectorAll(".tooltipped");
    this.modalTooltip = M.Tooltip.init(elemsTool);
  },
};
</script>

<style scoped>
.btn-cart {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #ff7043;
}
.leaflet-touch .leaflet-bar a {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #204ecc;
  border-radius: 50%;
  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;
}
.leaflet-control-zoom {
  margin: 0px 50px 50px 0px !important;
}
.leaflet-control-zoom a {
  color: white !important;
  background: rgba(0, 0, 0, 0.5) !important;
  transition: all 0.5s ease;
}
.leaflet-control-zoom a:hover {
  color: white !important;
  background: rgba(0, 0, 0, 0.9) !important;
}
.card-action {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
</style>

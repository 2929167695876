<template>
  <div>
    <label class="timezone" for="timezone">Выберите часовой пояс:</label>
<!--    <div class="waiting-submit" v-if="disable_time">-->
<!--      <span class="helper-text orange-text text-accent-4">Обновление отправлено</span>-->
<!--      <img src="../assets/svg/network-activity.svg" alt="wait">-->
<!--    </div>-->
<!--    @change="handleChange"-->
    <select class="select" name="timezone" id="timezone" :disabled="disable_time" v-model="selectedTimezone">
      <option v-for="timezone in computedTimezones" :key="timezone.value" :value="timezone.value">
        {{ timezone.label }}
      </option>
    </select>
  </div>
</template>

<script>
  import M from "materialize-css";

  export default {
    name: "TimezoneSelector",
    props: {
      disable_time: {
        type: Boolean,
        default: false
      },
      nowTimeZone: {
        type: String,
        default: ''
      }
    },
    computed: {
      selectedTimezone: {
        get() {
          return this.nowTimeZone || '';
        },
        set(newValue) {
          console.log('НОВОЕ ЗНАЧЕНИЕ',newValue)
          this.$emit('timezoneSelected', newValue);
        }
      },
      computedTimezones() {
        return Array.from({ length: 25 }, (_, i) => {
          const offset = i - 12;
          return {
            value: `${offset >= 0 ? '+' : ''}${offset}:00`,
            label: `UTC ${offset >= 0 ? '+' : ''}${offset}:00`
          };
        });
      }
    },
    methods: {
      // handleChange() {
      //   this.$emit("timezoneSelected", this.selectedTimezone);
      // },
      initMaterializeSelect() {
        const selects = this.$el.querySelectorAll('.select');
        M.FormSelect.init(selects);
      },
      updateSelect() {
        M.FormSelect.init(this.$el.querySelectorAll(".select"));
      },
      destroyMaterializeSelect() {
        const selects = this.$el.querySelectorAll('.select');
        selects.forEach(select => {
          const instance = M.FormSelect.getInstance(select);
          if (instance) {
            instance.destroy();
          }
        });
      }
    },
    mounted() {
      this.initMaterializeSelect();
    },
    updated() {
      this.initMaterializeSelect();
    },
    beforeDestroy() {
      this.destroyMaterializeSelect();
    }
  };
</script>

<style scoped>


</style>/

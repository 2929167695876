<template>
  <tbody>
    <tr>
      <td>
        <div class="table-report__text-and-description">
          <p class="table-text__title">{{ item_log.serial }}</p>
        </div>
      </td>
      <td>
        <div class="table-report__text-and-description">
          <p class="table-text__title">{{ item_log.latitude }}</p>
          <p class="table-text__title">{{ item_log.longitude }}</p>
        </div>
      </td>

      <td>
        <p class="table-text__title">{{ item_log.speed }} км/ч</p>
      </td>

      <td>
        <div class="table-report__text-and-description">
          <p class="table-text__title">{{ item_log.deviceTemperature }} С</p>
        </div>
      </td>

      <td class="table-report__td-center">
        <div class="table-status success">
          <p class="table-status__text white-text">
            {{ item_log.batteryVoltage }} %
          </p>
        </div>
      </td>
      <td class="">
        <p class="table-text__title">
          <type-mode :type="item_log.workMode" />
        </p>
      </td>
    </tr>
  </tbody>
</template>

<script>
import TypeMode from "../../TypeMode.vue";
export default {
  components: { TypeMode },
  name: "AppTableItemLog",
  props: {
    item_log: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>

// store/modules/socket.js
// import socketService from "@/helpers/socketService";
import lights from "./lights";
import demir from "./demir";
import trackers, { actionTypesTracker } from './trackers'
// import { actionTypesSocket, mutationTypesSocket } from '../socketTypes';
// import socketService from '../../services/socketService';

export const actionTypesSocket = {
  CONNECT: '[socket] CONNECT',
  DISCONNECT: '[socket] DISCONNECT',
  RECONNECT: '[socket] RECONNECT',
};

export const mutationTypesSocket = {
  SET_CONNECTED: 'SET_CONNECTED',
  TIMER_MESSAGE_RECEIVED: 'TIMER_MESSAGE_RECEIVED',
  DIMMER_MESSAGE_RECEIVED: 'DIMMER_MESSAGE_RECEIVED',
  TRACKER_MESSAGE_RECEIVED: 'TRACKER_MESSAGE_RECEIVED',
  SET_RECONNECT_ERROR: 'SET_RECONNECT_ERROR',
};


const state = {
  isConnected: false,
  // timerMessage: {},
  // dimmerMessage: {},
  // allDemir: [],  // Предполагаемое состояние всех диммеров
  // allTimers: [], // Предполагаемое состояние всех таймеров
  reconnectError: false,
};


const mutations = {
  [mutationTypesSocket.SET_CONNECTED](state, isConnected) {
    state.isConnected = isConnected;
    state.reconnectError = !isConnected;
  },
  [mutationTypesSocket.TIMER_MESSAGE_RECEIVED](state, message) {
    const index = lights.state.lights.findIndex(timer => timer.id === message.id);
    if (index !== -1) {
      lights.state.lights.splice(index, 1, {...lights.state.lights[index], ...message});
    }
  },
  [mutationTypesSocket.DIMMER_MESSAGE_RECEIVED](state, message) {
    const index =  demir.state.allDemir.findIndex(dimmer => dimmer.dimmerId === message.id);
    if (index !== -1) {
      demir.state.allDemir.splice(index, 1, {...demir.state.allDemir[index], ...message});
    }
  },
  [mutationTypesSocket.TRACKER_MESSAGE_RECEIVED](state, message) {
    const index = trackers.state.allTrackers.findIndex(tracker => tracker.beaconId === message.beaconId);
    // console.log('Старый объект', trackers.state.allTrackers[index])
    if (index !== -1) {

      trackers.state.allTrackers.splice(index, 1, {...trackers.state.allTrackers[index], ...message});
      if (trackers.state.itemTracker.beaconId ===  message.beaconId ) {
        trackers.state.itemTracker =  message
      }
      import("@/store").then(({ default: store }) => {
          store.dispatch(actionTypesTracker.getAllCommands, trackers.state.itemTracker.serial)
              .then((resp) => {
                console.log(resp, 'resp in socket')
              })
              .catch((er) => {
                console.log("Ошибка", er);
              });
      });
      // console.log('ЗАМЕНА по beaconId', message)
    }
  },
  [mutationTypesSocket.SET_RECONNECT_ERROR](state, hasError) {
    state.reconnectError = hasError;
  }
};

const actions = {
//   [actionTypesSocket.CONNECT]({ commit }) {
//     console.log(commit)
//     socketService.connect();
//   },
//   [actionTypesSocket.DISCONNECT]({ commit }) {
//     console.log(commit)
//     socketService.disconnect();
//   },
//   [actionTypesSocket.RECONNECT]({ commit }) {
//     console.log(commit)
//     socketService.connect(true);
//   }
};

export default {
  state,
  mutations,
  actions,
};

<template>
  <div class="blockSide">
    <Header @getOpenReport="getOpenReport" @getCloseReport="getCloseReport" :isOpenSideNave="isOpenSideNave"/>

    <ul id="slide-out" class="sidenav sidenav-position">
      <div
          class="sidebar-close index-color"
          v-if="this.modalInstance && this.modalInstance.isOpen === true"
      >
        <div
            class="sidebar-add__block modal-trigger"
            data-target="modalAddBlock"
        >
          <a class="icon-add" @click="closeSideBar()"
          ><i class="material-icons">add</i></a
          >
          <p class="sidebar-add__text">Добавить устройство</p>
        </div>
        <a class="icon-close" @click="closeSideBar()"
        ><i class="material-icons">close</i></a
        >
      </div>
      <div class="sidenav__select-wrap">
        <div class="input-field col s12 m6">
          <!-- :disabled="checkedTrackers.length === 0 || !startDate || !endDate"-->
          <div
              :class="{
              'buttonR': true,
              'waves-effect': true,
              'waves-light': true,
              'btn-largeR': true,
              'whiteR': !isActive,
              'blueR':  isActive,// Добавляем класс для активного состояния
              'real-time-btn': true,
            }"
              @click="handleBuildRoute"
          >
            <span>
              <img
                  src="../assets/glonas/car-hatchback-svgrepo-com.svg"
                  alt="switch-car-icon"
                  :class="{
               'realtime-icon': true,
              'realtime-icon-active': isActive,
              'realtime-icon-not-active': !isActive,
              }"
              >
            </span>
            <span>
              <span class="text-realtime">
                {{ realtimeButtonName }}
              </span>
            </span>
          </div>
          <!--          <select-->
          <!--            class="icons sidenav__select"-->
          <!--            v-model="selectList"-->
          <!--            @change="onChangeSelected"-->
          <!--          >-->
          <!--            <option value="" disabled selected>Выберите тип</option>-->
          <!--            <option value="3">Трекер</option>-->
          <!--&lt;!&ndash;            <option value="1" selected>Таймеры</option>&ndash;&gt;-->
          <!--            &lt;!&ndash; <option value="2">Демирование</option> &ndash;&gt;-->
          <!--          </select>-->
          <!--          <div class="row" style="margin-bottom: 0px !important" v-if="selectList">-->
          <div class="row" style="margin-bottom: 0px !important">
            <div class="data-tab">
              <div class="data-tab__data-tab">
                <div
                    class="data-tab__btn"
                    @click="activeBlock('NAME')"
                    :class="{ 'active-btn': this.isActiveName }"
                >
                  <p
                      class="data-tab__text"
                      :class="{ 'active-text': this.isActiveName }"
                  >
                    Поиск по имени
                  </p>
                </div>
                <div
                    class="data-tab__btn"
                    @click="activeBlock('SERIAL')"
                    :class="{ 'active-btn': this.isActiveSerial }"
                >
                  <p
                      class="data-tab__text"
                      :class="{ 'active-text': this.isActiveSerial }"
                  >
                    Поиск по IMEI
                  </p>
                </div>

              </div>
            </div>
            <div class="col-xs-8 col-xs-offset-2">
              <form method="get" id="searchForm" class="input-group" v-if="isActiveName">
                <input
                    type="text"
                    class="input-group__input"
                    name="search"
                    v-model="searchName"
                    placeholder="Поиск по имени"
                />
                <span class="input-group__btn">
                  <i class="material-icons">search</i>
                </span>
              </form>
              <form method="get" id="searchForm" class="input-group" v-if="isActiveSerial">
                <input
                    type="text"
                    class="input-group__input"
                    name="search"
                    v-model="searchSerial"
                    placeholder="Поиск по IMEI"
                />
                <span class="input-group__btn">
                  <i class="material-icons">search</i>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- БЛОК SIDENAV TIMER -->
      <div
          class=""
          style="height: calc(100% - 100px)"
          v-if="this.selectList === '1'"
      >
        <div
            class=""
            style="overflow-y: auto; height: calc(100% - 77px)"
            v-if="lights"
        >
          <!-- @itemData="itemData(item)" -->
          <item-list
              v-for="(item, index) in filteredListLights"
              :key="item.id"
              @itemData="itemData"
              @zoomToMarker="zoomToMarker(item)"
              @itemDataHistory="itemDataHistory(item)"
              @updateCheck="updateCheck()"
              v-bind:item_data="item"
              v-bind:index="index"
              v-model="checked"
          />
        </div>
      </div>
      <div
          class="sidenav__checkbox-all index-color"
          v-if="this.selectList === '1'"
      >
        <p>
          <label>
            <input type="checkbox" ref="myCheckbox" :disabled=true class="filled-in" v-model="selectAllTimer"/>

            <span class="title-text">Выбрать все</span>
          </label>
        </p>



        <div
            class="sidenav__send-button tooltipped modal-trigger"
            data-target="modalAllTimers"
            name="action"
            data-position="top"
            :data-tooltip="'Отправить всем'"
            v-if="this.checked.length > 1"
        >
          <i class="material-icons white-text right">send</i>
        </div>
      </div>
      <div
          class="sidenav__checkbox-all index-color"
          v-if="this.selectList === '1'"
      >
        <p>
          <label>
            <input type="checkbox" ref="myCheckbox" :disabled=true class="filled-in" v-model="selectAllTimer"/>

            <span class="title-text">Выбрать все</span>
          </label>
        </p>

      </div>
      <!-- БЛОК SIDENAV DIMER -->
      <div
          class=""
          style="height: calc(100% - 155px)"
          v-if="this.selectList === '2'"
      >
        <div
            class=""
            style="overflow-y: auto; height: calc(100% - 77px)"
            v-if="demir"
        >
          <item-demir-list
              v-for="item in filteredListDemir"
              :key="item.dimmerId"
              @zoomToMarker="zoomToMarker(item)"
              @updateCheckDimer="updateCheckDimer()"
              @clickSettings="clickSettings()"
              v-bind:item_data="item"
              v-model="checkedDemir"
          ></item-demir-list>
        </div>
      </div>
      <div
          class="sidenav__checkbox-all index-color"
          v-if="this.selectList === '2'"
      >
        <p>
          <label>
            <input type="checkbox" ref="myCheckbox" :disabled=true class="filled-in" v-model="selectAllDimer"/>

            <span class="title-text">Выбрать все</span>
          </label>
        </p>
        <div
            class="sidenav__send-button tooltipped modal-trigger"
            data-target="modalAllSettingsDemmir"
            name="action"
            data-position="top"
            :data-tooltip="'Отправить всем'"
            v-if="this.checkedDemir.length > 1"
        >
          <i class="material-icons white-text right">send</i>
        </div>
      </div>
      <div style="height: calc(100% - 150px)">
        <div class="" style="overflow-y: auto; height: calc(100% - 100px)">
          <!-- БЛОК SIDENAV TRACKER -->
          <!--        v-if="this.selectList === '3'"-->
          <list-trackers
              :filteredListTrackers="filteredListTrackers"
              :isActive="isActive"
              @zoomToMarker="zoomToMarker"
          />
        </div>
      </div>
    </ul>
    <!-- v-if="!this.isOpenSideNave" -->

    <a
        data-target="slide-out"
        style="margin: 20px 0px 0px 20px; position: absolute; z-index: 100"
        class="sidenav-trigger btn-floating btn-large index-color"
        @click="openSideBar"
    ><i class="material-icons">menu</i></a
    >
  </div>
</template>

<script>
import M from "materialize-css";
import Header from "./Header.vue";
import {mapState} from "vuex";
import ItemList from "./ItemList.vue";
import {actionTypes} from "@/store/modules/lights";
import {actionTypesDemir} from "@/store/modules/demir";
import ItemDemirList from "./ItemDemirList.vue";
import ListTrackers from "./Tracker/ListTrackers.vue";
// import moment from "moment-timezone";
import {actionTypesRoute} from "@/store/modules/route";

// import { actionTypesModal } from '@/store/modules/all-modal'


export default {
  name: "AppSideNav",
  data() {
    return {
      checked: [],
      checkedDemir: [],
      isActiveSerial: false,
      isActiveName: true,
      // realtimeButtonName: "Реальное время",

      // isSearchTimerName: false,
      // isSearchTimerAddress: false,
      modalInstance: null,
      modalTooltip: null,
      modalSelect: null,
      selectList: "",
      isOpenSideNave: false,
      isCheckAll: false,
      isCheckAllDemir: false,
      search: "",
      searchName: "",
      searchSerial: "",
      startDate: null,
      endDate: null,
      isActive: false,
      // isRouteVisible: false,

    };
  },
  components: {
    Header,
    ItemList,
    ItemDemirList,
    ListTrackers,
  },

  methods: {
    toggleRouteVisibility(check) {

      this.$emit('toggle-route-visibility', check);

    },
    handleBuildRoute() {
      this.getRoutingCar(); // Метод для построения маршрута
      // Метод для переключения видимости маршрута

    },
    itemData(items) {
      console.log(items, "sideNav");
      this.$emit("itemIndex", items);
    },
    // itemTracker(items) {
    //   console.log(items, "itemTracker");
    //   this.$emit("itemTrackerData", items);
    // },
    activeBlock(tab) {
      if (tab === "SERIAL") {
        this.isActiveSerial = true;
        this.isActiveName = false;
        this.searchSerial = "";
      } else if (tab === "NAME") {
        this.isActiveSerial = false;
        this.isActiveName = true;
        this.searchName = "";
      }
    },

    getRoutingCar() {
      // const formattedStartDate = this.startDate ? moment(this.startDate).format('YYYY-MM-DD HH:mm') : null;
      // const formattedEndDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DD HH:mm') : null;
      // Извлечение serial из checkedTrackers
      const serialsList = this.checkedTrackers.map(tracker => tracker.serial);

      // if (this.realtimeButtonName === "Реальное время") {
      //   this.realtimeButtonName = "История"
      // } else {
      //   this.realtimeButtonName = "Реальное время"
      // }

      this.$store.dispatch(actionTypesRoute.getRoute, {
        serials: serialsList,
        startDate: "2024-08-26 00:00",
        endDate: "2024-08-29 00:00",
      }).then((response) => {
        if (response.length === 0) {
          this.toggleRouteVisibility(false);
          alert('Маршрут на данном временном отрезке не найден!');
        } else {
          console.log(response);

          // this.isRouteVisible = !this.isRouteVisible;
          this.isActive = !this.isActive;
          this.toggleRouteVisibility(this.isActive);
        }

      })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },

    clickSettings() {
      console.log("clickSettings");
      this.$emit("clickSettings");
    },
    zoomToMarker(items) {
      this.$emit("zoomToMarker", items);
    },
    updateCheck() {
      this.$store.dispatch(actionTypes.checkedTimer, this.checked);
    },
    updateCheckDimer() {
      this.$store.dispatch(actionTypesDemir.checkedDemir, this.checkedDemir);
      // console.log("updateCheckDimer updateCheckDimer");
    },
    getOpenReport() {
      this.$emit("getOpenReport");
      this.closeSideBar();
    },
    getCloseReport() {
      this.$emit("getOpenReport");
      this.openSideBar();
    },
    onChangeSelected() {
      if (this.selectList === "1") {
        this.$store
            .dispatch(actionTypes.getList)
            .then(() => {
              // console.log(resp, "ТАЙМЕРЫ");
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      } else {
        this.$store
            .dispatch(actionTypesDemir.getAllDemir)
            .then(() => {
              // console.log(resp, "ДЕМИРОВАНИЕ");
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },

    itemDataHistory(items) {
      this.$emit("itemIndex", items);
      this.$store
          .dispatch(actionTypes.getHistoryTimer, {
            unitId: items.id,
            type: items.type,
          })
          .then((resp) => {
            console.log(resp);
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
    async closeSideBar() {
      await this.modalInstance.close();

      this.isOpenSideNave = false;
    },
    openSideBar() {
      this.modalInstance.open();
      this.isOpenSideNave = true;
    },

    // searchNameTimer() {
    //   this.isSearchTimerName = !this.isSearchTimerName;
    // },
    // searchAddressTimer() {
    //   this.isSearchTimerAddress = !this.isSearchTimerAddress;
    // },
  },
  computed: {
    ...mapState({
      // isLoading: (state) => state.lights.isSubmitting,
      lights: (state) => state.lights.lights,
      demir: (state) => state.demir.allDemir,
      trackers: (state) => state.trackers.allTrackers,
      isLoading: (state) => state.trackers.isLoading,
      allReportsTr: (state) => state.trackers.allReportsTr,
      checkedTrackers: (state) => state.trackers.checkedTrackers,
      routes: (state) => state.route.routes
    }),
    realtimeButtonName() {
      return this.isActive ? 'История' : 'Реальное время';
    },

    // chipColorOrange: function () {
    //   return {
    //     orange: this.isSearchTimerName,
    //   };
    // },
    // chipColorGreen: function () {
    //   return {
    //     green: this.isSearchTimerAddress,
    //   };
    // },
    searchReact() {
      return this.search;
    },
    filteredListTrackers() {
      if (this.searchName) {
        return this.trackers.filter((post) => {
          return post.name
              .toLowerCase()
              .includes(this.searchName.toLowerCase());
        });
      } else if (this.searchSerial) {
        return this.trackers.filter((post) => {
          return post.serial
              .toLowerCase()
              .includes(this.searchSerial.toLowerCase());
        });
      } else {
        return this.trackers;
      }
    },
    filteredListLights() {
      if (this.search) {
        return this.lights.filter((post) => {
          return post.nameTimer
              .toLowerCase()
              .includes(this.search.toLowerCase());
        });
      } else {
        return this.lights;
      }
    },
    filteredListDemir() {
      if (this.search) {
        return this.demir.filter((post) => {
          console.log(post, "post.name.toLowerCase()");
          if (post.name !== null) {
            return post.name.toLowerCase().includes(this.search.toLowerCase());
          }
        });
      } else {
        return this.demir;
      }
    },
    selectAllTimer: {
      get: function () {
        return this.lights ? this.checked.length == this.lights.length : false;
      },
      set: function (value) {
        var checked = [];

        if (value) {
          this.lights.forEach(function (lights) {
            checked.push(lights);
          });
        }

        this.checked = checked;
        this.$store.dispatch(actionTypes.checkedTimer, this.checked);
      },
    },
    selectAllDimer: {
      get: function () {
        return this.demir
            ? this.checkedDemir.length == this.demir.length
            : false;
      },
      set: function (value) {
        var checkedDemir = [];

        if (value) {
          this.demir.forEach(function (demir) {
            checkedDemir.push(demir);
          });
        }

        this.checkedDemir = checkedDemir;
        this.$store.dispatch(actionTypesDemir.checkedDemir, this.checkedDemir);
      },
    },
    // checkedTracker
  },

  mounted() {
    this.$nextTick(function () {
      const elem = document.querySelector(".sidenav");
      this.modalInstance = M.Sidenav.init(elem);
      this.openSideBar();

      var elems = document.querySelectorAll("select");
      this.modalSelect = M.FormSelect.init(elems);

      var elemsTool = document.querySelectorAll(".tooltipped");
      this.modalTooltip = M.Tooltip.init(elemsTool);
      if (this.isActive) {
        this.$refs.myCheckbox.setAttribute('disabled', 'disabled');
      }
    });
  },
};
</script>

<style>
#sidenav-1 {
  margin-top: 64px;
}

#slide-out {
  margin-top: 64px;
}

#dropdown1,
#dropdown2 {
  margin-top: 64px;
}

.dropdown-content li > a {
  color: #00bcd4 !important;
}

.sidenav {
  width: 360px !important;
}

.side-nav li.active {
  background-color: #00bcd4 !important;
}

.side-nav li.user-li.active {
  background-color: #ac3973 !important;
}

.side-nav li.active.lone a,
.side-nav li.active ul li:first-child,
.side-nav li.active.lone a svg,
.side-nav li.active ul li div.collapsible-header p svg {
  color: #ffffff !important;
}

.sidenav .collapsible li,
.sidenav.fixed .collapsible li {
  padding: 0;
  flex: 1 0 auto;
  max-height: 80%;
}

.my-icon {
  font-size: 1.5em;
  margin-right: 14px;
  color: #666666;
}

.changed {
  margin-left: 5px;
  color: #666666;
}

.no-margin {
  margin: 0px;
}

.sidenav-overlay {
  height: 0px;
  width: 0px;
}

.user-li {
  background-color: #c6538c !important;
}


div.real-time-btn {
  color: rgba(17, 41, 107, 0.45);
  width: 100%;
  font-weight: 600;
  font-size: 1.2em;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
}

img.realtime-icon {
  margin-right: 10px;
  width: 30px;
  height: auto;
  display: flex;
  fill: #1e2d4f !important;

}

.text-realtime {
  display: inline-block;
}

.buttonR {
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s, box-shadow 0.3s;
  //border: none;
  cursor: pointer;
}

.blueR {
  background-color: #6378A6; /* Синий цвет для активного состояния */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid #6378A6 !important;
  color: white !important;
}

.whiteR {
  background-color: transparent;
  color: #6378A6 !important;
  box-shadow: none;
  border: 2px solid #6378A6;
  //border-: 20px !important;
}

.buttonR:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.realtime-icon-not-active {
  margin-right: 8px;
  filter: invert(46%) sepia(32%) saturate(502%) hue-rotate(183deg) brightness(94%) contrast(93%);

}


.realtime-icon-active {
  margin-right: 8px;
  filter: invert(98%) sepia(0%) saturate(7%) hue-rotate(183deg) brightness(101%) contrast(105%);
}

.sidenav__checkbox-all .filled-in[disabled] {
  background-color: #ccc !important; /* Переопределение стиля для disabled чекбокса */
  pointer-events: none !important; /* Убирает возможность взаимодействия */
}
</style>

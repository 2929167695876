<template>
  <div class="table-report">
    <table class="table-report__border striped centered">
      <thead>
        <tr>
          <th>Скачать</th>
          <th>Дата создания</th>
          <th>Отчетный период</th>
          <th>Статус</th>
          <th>Действия</th>
        </tr>
      </thead>

      <table-body-report
        v-for="report in allReportsTr"
        :key="report.reportId"
        :item_report="report"
      />
    </table>
  </div>
</template>

<script>
import TableBodyReport from "./TableBodyReport.vue";
import { mapState } from "vuex";

export default {
  components: { TableBodyReport },
  name: "AppTableReport",
  computed: {
    ...mapState({
      isLoading: (state) => state.trackers.isLoading,
      allReportsTr: (state) => state.trackers.allReportsTr,
    }),
  },
};
</script>

<style></style>

<template>
  <div class="type-mode">
    <div class="table-status success" v-if="type !== 1">
      <p class="table-status__text white-text">Информационный</p>
      <div class="table-status__icon">
        <i class="material-icons white-text">done</i>
      </div>
    </div>
    <div class="table-status danger" v-else>
      <p class="table-status__text white-text">Угон</p>
      <div class="table-status__icon">
        <i class="material-icons white-text">navigation</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTypeMode",
  props: {
    type: {
      type: Number,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>

<template>
  <div v-if="item_trackers">
    <div id="modalDeleteTracker" class="modal">
      <div class="modal-content">
        <h5>Вы действительно хотите удалить?</h5>
        <p>Название блока: {{item_trackers.name}}</p>
      </div>
      <div class="modal-footer">
        <button class="modal-close waves-effect waves-green btn-flat" @click="closeModal">Отменить</button>
        <button class="modal-close waves-effect waves-red btn-flat" @click="deleteItemTracker">Удалить</button>
      </div>
    </div>
  </div>
</template>

<script>
  import M from "materialize-css";
  import { actionTypesTracker } from "@/store/modules/trackers";
  import { actionTypesModal } from '@/store/modules/all-modal'
  import { mapState } from 'vuex'

  export default {
    name: "modalDeleteTracker",
    data() {
      return {};
    },
    computed: {
      ...mapState({
        item_trackers: (state) => state.trackers.itemTracker,
      }),
  },
    methods: {
      handleClick() {
        this.modalInstance.close();
      },
      closeModal() {
        this.$store.dispatch(actionTypesModal.closeModalDeleteTracker)
      },
      deleteItemTracker() {
        this.$store
            .dispatch(actionTypesTracker.deleteTracker, this.item_trackers.serial)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },

    mounted() {
      const modal = document.querySelector(".modal");
      this.modalInstance = M.Modal.init(modal);
    },
  };
</script>
<style scoped>
.modal {
  width: 30% !important;
  top:35% !important;
}
.modal .modal-content {
  //padding: 0px;
  border-bottom: 1px solid lightgrey;
}
.modal .modal-footer {
  height: initial !important;
  border-radius: 0px 0px 10px 10px;
}
</style>
